import { Outlet } from "react-router-dom";

import { Column, Row } from "components";
import { MAIN_LAYOUT_ID } from "config/constants";
import { colors } from "config/theme";
import styled from "styled-components";

import { ScrollableColumn } from "./components";

const Layout = styled(Row)`
  height: 100vh;
  overflow: auto;
`;

const PortalContainer = styled(Column)`
  height: 0;
`;

const StyledScrollableColumn = styled(ScrollableColumn)`
  margin-top: -10px;
  padding-top: 10px;
  overflow-x: hidden;
`;

export const MainLayout = () => (
  <>
    <PortalContainer id="reactPortal" />
    <Layout>
      <Column
        id="mainContent"
        flex={1}
        backgroundColor={colors.background}
        minWidth={0}
      >
        <StyledScrollableColumn id={MAIN_LAYOUT_ID}>
          <Outlet />
        </StyledScrollableColumn>
      </Column>
    </Layout>
  </>
);
