import { Color, colors } from "config/theme";
import styled, { css } from "styled-components";
import {
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  flexbox,
  layout,
  space,
} from "styled-system";

export interface DivProps extends SpaceProps, LayoutProps, FlexboxProps {
  backgroundColor?: Color;
}

export interface RowColumnProps extends DivProps {
  gap?: boolean | number;
  spaceAround?: boolean;
}

export const Div = styled.div<DivProps>`
  background: ${(props) => props.backgroundColor || "transparent"};
  ${space}
  ${layout}
  ${flexbox}
`;

export const Row = styled(Div)<RowColumnProps>`
  display: flex;
  ${(props) =>
    props.gap &&
    css`
      gap: ${typeof props.gap === "number"
        ? props.gap
        : props.theme.horizontalGap}px;
    `}
  ${({ spaceAround }) =>
    spaceAround &&
    css`
      justify-content: space-around;
    `}
`;

export const Column = styled(Row)<RowColumnProps>`
  flex-direction: column;
  ${(props) =>
    props.gap &&
    css`
      gap: ${typeof props.gap === "number"
        ? props.gap
        : props.theme.verticalGap}px;
    `}
`;

export const Line = styled.hr`
  width: 100%;
  margin: 0px;
  border: 0px;
  height: 1px;
  background-color: ${colors.grey200};
`;
