import { Div } from "components";
import useWindowResize from "hooks/useWindowResize";
import { Footer } from "layouts/components/Footer/Footer";
import { MainHeader } from "layouts/components/MainHeader/MainHeader";
import styled from "styled-components";
import Container from "styled/Container";
import { media } from "utils/media";
import maintenancePattern from "../assets/Images/maintanance-pattern.svg";

import smallmaintenancePattern from "../assets/Images/maintanance-patter-small.svg";
const Logo = styled.img`
  width: 140px;
  height: 40px;
  margin: 25px 10px 25px 50px;
  @media only screen and (max-width: 640px) {
    width: 190px;
    height: auto;
    margin: 25px 10px;
  }
`;

const DivMain = styled(Div)`
  width: 100%;
  display: flex;
  padding-top: 85px;
  margin: auto;
`;

const TextDiv = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.md`
    max-width: 557px;
  `};
  ${media.lg`
    max-width: 770px;
  `}
`;

const MaintenancePattern = styled(Div)`
  background: url(${process.env.PUBLIC_URL + maintenancePattern});
  width: 690px;
  position: absolute;
  top: 189px;
  height: 670px;

  ${media.lg`
    right: -30%;
  `};
  ${media.xl`
    right: 0;
  `}
`;

const SmallMaintenancePattern = styled(Div)`
  background: url(${process.env.PUBLIC_URL + smallmaintenancePattern});
  width: 60px;
  position: absolute;
  right: 5px;
  top: 131px;
  height: 345px;
  ${media.md`
    width: 80px;
    top: 131px;
    height: 460px;
  `}
`;

const MainDiv = styled.div`
  background: #ede6df;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  display: flex;
  height: 543px;
  ${media.md`
    height: 551px;
  `}
  ${media.lg`
    height: 759px;
  `};
`;

const Paragraph = styled.p`
  padding-bottom: 10px;
  ${media.lg`
    color: #2d3232;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.5px;

    padding-bottom: 30px;
  `}
`;

const StyledText = styled.p`
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  color: #2d3232;
  ${media.md`
    font-size: 48.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 48.5px;
    letter-spacing: -1px;
    max-width: 557px;
  `};

  ${media.lg`
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1px;
    max-width: 770px;
 `}
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  ${media.sm`
    width: 100%;
  `};

  ${media.md`
    width: 100%;
  `};

  ${media.lg`
    width: 100%;
  `};

  ${media.xl`
    width: 1280px;
  `}
`;

const TextContainer = styled.div`
  width: 100%;
  padding-top: 138px;

  ${media.md`
    width: 557px;
    padding-top: 130px;
  `};
  ${media.lg`
    padding-top: 159px;
    width: 770px;
  `}
`;

const StyledParagraphText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
  padding-top: 20px;
  ${media.md`
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: -0.5px;
    padding-top: 20px;
  `}
  ${media.lg`
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.2px;
    letter-spacing: -0.5px;
    padding-top: 40px;
  `}
`;

export const MaintentancePage = () => {
  const { width } = useWindowResize();

  return (
    <>
      <MainDiv>
        <Container>
          <MainHeader />

          <TextContainer>
            <Paragraph>Error 503</Paragraph>
            <StyledText>
              We're experiencing some technical difficulties.
            </StyledText>
            <StyledParagraphText>
              Try refreshing your browser or check back shortly.
            </StyledParagraphText>
          </TextContainer>
          {width < 1024 && <SmallMaintenancePattern />}
          {width > 1024 && <MaintenancePattern />}
        </Container>
      </MainDiv>
      <Footer footermode="light"></Footer>
    </>
  );
};
