import { ConfigurationIcon, IntegrationIcon, WebApiDbIcon } from "assets/icons";
import { BubbleMessage, Column, Div, Row, useBubbleMessage } from "components";
import { colors } from "config/theme";
import {
  Button,
  PasswordInput,
  RadioButton,
  RadioGroup,
  Text,
  TextInput,
} from "gps-design-system";
import useWindowResize from "hooks/useWindowResize";
import { Footer } from "layouts/components/Footer/Footer";
import { MainHeader } from "layouts/components/MainHeader/MainHeader";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import Container from "styled/Container";
import { media } from "utils/media";
import Account from "../Services/signUp";
import lightBluePattern from "../assets/Images/lightBluePattern.svg";

interface IFormInputs {
  firstName: string;
  lastName: string;
  password: string;
  companyName: string;
  email: string;
  userName: string;
  GPDR: boolean;
  termsAndConditions: boolean;
  existingCustomer: boolean;
}
const Wrapper = styled(Column)`
  position: relative;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  z-index: 1;
  min-height: 80vh;
  ${media.md`
    width: 536px;
    margin: auto;
  `}
  ${media.lg`
    padding-left:40px;
    width: 540px;
  `}
`;

const FormContainer = styled(Div)`
  background-color: ${colors.white};
  padding: 30px;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 409px;
  margin-top: 50px;
  ${media.lg`
    min-width: 471px;
  `}
`;

const DivMain = styled(Div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ede6df;
  justify-content: space-between;
  padding-top: 111px;
  padding-bottom: 111px;
  ${media.lg`
    flex-direction: row;
  `}
`;

const Errors = styled(Div)`
  color: ${colors.grey300};
  margin-top: 1%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-left: 16px;
  @media only screen and (max-width: 640px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-left: 0px;
    padding-top: 20px;
  }
`;

const MainContainer = styled(Div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ede6df;
  @media only screen and (max-width: 640px) {
    margin-bottom: 20px;
  }
`;

const Logo = styled.img`
  width: 140px;
  height: 40px;
  margin: 25px 10px 25px 50px;
  @media only screen and (max-width: 640px) {
    width: 190px;
    height: auto;
    margin: 25px 10px;
  }
`;

const LightBluePattern = styled(Div)`
  background: url(${process.env.PUBLIC_URL + lightBluePattern});
  width: 120px;
  height: 1208px;
  margin-top: 100px;
  display: block;
  position: absolute;
  top: 145px;
  right: 0;
`;

const GettingStartedContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  justify-self: flex-start;
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

const IntroText = styled(Div)`
  display: flex;
  flex-direction: row;
  max-width: 630px;
  gap: 20px;
  align-items: center;
  font-size: 21px;
  margin-bottom: 15px;
  ${media.lg`
  margin-bottom: 58px;

  `}
`;

const Icon = styled(Div)`
  font-size: 2.5rem;
  min-width: 55px;
  width: 55px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${media.md`
    gap: 32px;
  `}
`;

const Link = styled.a`
  text-decoration: underline;
  color: #251d97;
`;

const LabelInput = styled(Column)<{ width?: string }>`
  width: ${({ width }) => (width ? width : "100%")};
  gap: 4px;
  min-height: 50px !important;
`;
const RequiredSymbol = styled(Text)`
  color: #ff249e !important;
`;

const LegalText = styled(Div)`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  color: #2d3232;
`;

const LabelText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.02px;
  color: #2d3232;
  ::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
  }
  ${media.lg`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.02px;
  `}
`;

const RequiredLabel = ({ label }: { label: string }) => (
  <Row>
    <LabelText style={{ color: "#2D3232" }}>{label}</LabelText>
    <RequiredSymbol>*</RequiredSymbol>
  </Row>
);

const Title = styled.p`
  line-height: 1;
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 49px;
  max-width: 70%;
  ${media.lg`
    max-width: 100%;
    font-size: 60px;
    margin-bottom: 60px;
  `};
`;

const BlurbText = styled.p`
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.2px;
  ${media.lg`
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.2px;
  `}
`;

const StyledTextInput = styled(TextInput)`
  font-size: 16px;
  line-height: 1.4px;
  font-weight: 300;
  padding: 12px;
  box-shadow: none;
  min-height: 50px !important;
  height: 50px;
  & > :first-child {
    min-height: 50px !important;
  }
`;
const StyledPasswordInput = styled(PasswordInput)`
  font-size: 16px;
  line-height: 1.4px;
  font-weight: 300;
  min-height: 50px;
  padding: 12px;
  box-shadow: none;
`;

const StyledText = styled(Text)`
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.2px;
`;

const StyledParagraph = styled(Text)`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const StyledButton = styled(Button)`
  justify-self: flex-end;
  margin-top: auto;
  width: auto;
`;

const StyledPasswordText = styled.p`
  color: #a3a3a3 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const CustomStyledParent = styled.div`
  & div {
    ${media.md`
      height: 50px;
      display: flex;
      align-items: center;
    `}
  }
`;

const StyledCustomButton = styled(Button)`
  min-height: 47px;
`;

const StyledPargraphText = styled.p`
  color: #2d3232;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  ${media.md`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align:left;
  `}
`;

const StyledRadioButton = styled(RadioButton)`
  color: #2d3232;
`;

const EmailLink = styled.p`
  color: #251d97;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export const SignUp = () => {
  //#region
  const [existingCustomer, setExistingCustomer] = useState<boolean>();
  const [chcTerms, setchcTerms] = useState(true);
  const [firstNameFormErrors, setFirstNameFormErrors] = useState<any | null>();
  const [lastNameFormErrors, setLastNameFormErrors] = useState<any | null>();
  const [passwordFormErrors, setPasswordFormErrors] = useState<any | null>();
  const [emailFormErrors, setEmailFormErrors] = useState<any | null>();
  const [companyNameFormErrors, setCompanyNameFormErrors] = useState<
    any | null
  >();
  const [TACFormErrors, setTACFormErrors] = useState<any | null>();
  const [existingCustomerErrors, setExistingCustomerErrors] = useState<
    any | null
  >();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [passwordComplexityMessage, setPasswordComplexityMessage] = useState<
    any | null
  >(
    "Minimum 8 characters including at least 1 each of: uppercase, lowercase, digit and special character."
  );
  const { handleSubmit } = useForm<IFormInputs>({ mode: "onChange" });
  const { isVisible, showMessageBubble } = useBubbleMessage();
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isWarning] = useState(false);
  const [isSignUpButtonDisabled, setIsSignUpButtonDisabled] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState(
    "A verification email has been sent to your email address."
  );
  const windowSize = useWindowResize().width;

  const navigateToLogin = () => {
    window.open(`${process.env.REACT_APP_CLIENT}`, "_self");
  };

  useEffect(() => {
    isFormValidCheck();
  }, [chcTerms]);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    data.existingCustomer = existingCustomer ?? false;
    data.termsAndConditions = chcTerms;
    data.companyName = company;
    data.firstName = firstName;
    data.lastName = lastName;
    data.email = emailAddress.trim();
    data.password = password;
    const isEmailValid = emailValidation();
    const isPasswordValid = passwordValdation();
    if (isEmailValid && isPasswordValid) {
      setIsSignUpButtonDisabled(true);
      setPasswordFormErrors(null);
      setFirstNameFormErrors(null);
      setLastNameFormErrors(null);
      setEmailFormErrors(null);
      setCompanyNameFormErrors(null);
      setTACFormErrors(null);
      Account.SubmitFormData(data)
        .then((resp) => {
          setIsSignUpButtonDisabled(false);
          if (resp == "OK") {
            setShowConfirmation(true);
            setFirstName("");
            setLastName("");
            setPassword("");
            setExistingCustomer(undefined);
          }
        })
        .catch((err) => {
          setIsSignUpButtonDisabled(false);
          const listOfErrors = err.error?.response?.data?.errors;
          if (listOfErrors && listOfErrors.length > 0) {
            listOfErrors.forEach((element: any) => {
              if (element?.extensions?.fieldName) {
                switch (element?.extensions?.fieldName) {
                  case "UserPassword":
                    setPasswordFormErrors(element.message);
                    setPasswordComplexityMessage(null);
                    break;
                  case "FirstName":
                    setFirstNameFormErrors(element.message);
                    break;
                  case "Surname":
                    setLastNameFormErrors(element.message);
                    break;
                  case "UserEmail":
                    setEmailFormErrors(element.message);
                    break;
                  case "CompanyName":
                    setCompanyNameFormErrors(element.message);
                    break;
                  case "TermsAndConditions":
                    setTACFormErrors(element.message);
                    break;
                  case "ExistingCustomer":
                    setExistingCustomerErrors(element.message);
                    break;
                }
              } else {
                showMessageBubble();
                setIsError(true);
                setMessage(element.message);
              }
            });
          } else {
            showMessageBubble();
            setIsError(true);
            setMessage("Something went wrong. Please try again later.");
          }
        });
    } else {
      showMessageBubble();
      setIsError(true);
      setMessage("Please resolve the form errors before submitting");
    }
  };

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (emailAddress.trim().length > 0) {
      if (!emailAddress || regex.test(emailAddress.trim()) === false) {
        setEmailFormErrors(
          "The email is not in the right format. Please check and try again."
        );
        setPasswordComplexityMessage(null);
        return false;
      }
      return true;
    } else {
      setEmailFormErrors("Email is required.");
      setPasswordComplexityMessage(null);
      return false;
    }
  };

  const passwordValdation = () => {
    if (password && password.length < 8) {
      setPasswordFormErrors(
        "Minimum 8 characters including at least 1 each of: uppercase, lowercase, digit and special."
      );
      setPasswordComplexityMessage(null);
      return false;
    } else {
      return true;
    }
  };

  const checkForInvalidCharacters = (
    stringToCheck: string,
    textArea: string
  ) => {
    const errorMsg = "Input contains invalid character.";
    if (stringToCheck?.indexOf('"') > -1 || stringToCheck?.indexOf("\\") > -1) {
      setIsSignUpButtonDisabled(true);
      setErrorHandlers(textArea, errorMsg);
      return true;
    } else {
      setErrorHandlers(textArea, null);
    }
  };

  const setErrorHandlers = (formId: string, errorMsg: string | null) => {
    switch (formId) {
      case "firstNameInput":
        setFirstNameFormErrors(errorMsg);
        break;
      case "lastNameInput":
        setLastNameFormErrors(errorMsg);
        break;
      case "emailAddressInput":
        setEmailFormErrors(errorMsg);
        break;
      case "passwordInput":
        setPasswordFormErrors(errorMsg);
        break;
      case "yourCompanyInput":
        setCompanyNameFormErrors(errorMsg);
        break;
    }
  };

  const checkFormValues = () => {
    let formValues = false;
    let invalidCharacters = false;
    if (firstName.length > 40) {
      setErrorHandlers(
        "firstNameInput",
        "First Name cannot exceed 40 characters."
      );
      return false;
    }

    if (lastName.length > 80) {
      setErrorHandlers(
        "lastNameInput",
        "Last Name cannot exceed 80 characters."
      );
      return false;
    }

    if (emailAddress.length > 80) {
      setErrorHandlers(
        "emailAddressInput",
        "Email Address cannot exceed 80 characters."
      );
      return false;
    }

    if (
      firstName.trim() &&
      lastName.trim() &&
      emailAddress.trim() &&
      password.trim() &&
      company.trim() &&
      chcTerms &&
      existingCustomer !== undefined
    ) {
      formValues = true;
    } else {
      formValues = false;
    }

    let firstNameInput = !checkForInvalidCharacters(
      firstName.trim(),
      "firstNameInput"
    );
    let lastNameInput = !checkForInvalidCharacters(
      lastName.trim(),
      "lastNameInput"
    );
    let emailAddressInput = !checkForInvalidCharacters(
      emailAddress.trim(),
      "emailAddressInput"
    );
    let passwordInput = !checkForInvalidCharacters(
      password.trim(),
      "passwordInput"
    );
    let yourCompanyInput = !checkForInvalidCharacters(
      company.trim(),
      "yourCompanyInput"
    );

    if (
      firstNameInput &&
      lastNameInput &&
      emailAddressInput &&
      passwordInput &&
      yourCompanyInput
    ) {
      invalidCharacters = false;
    } else {
      invalidCharacters = true;
    }

    if (formValues && !invalidCharacters) {
      return true;
    } else {
      return false;
    }
  };

  const isFormValidCheck = () => {
    const isValid = checkFormValues();
    setIsSignUpButtonDisabled(!isValid);
  };

  const onExistingCustomerCheck = (event: string) => {
    if (event === "yes") {
      setExistingCustomer(true);
    } else {
      setExistingCustomer(false);
    }
  };

  useEffect(() => {
    isFormValidCheck();
  }, [existingCustomer]);
  //#endregion
  return (
    <>
      <BubbleMessage
        isVisible={isVisible}
        message={message}
        isWarning={isWarning}
        isError={isError}
      />
      <MainContainer>
        <div>
          <Container>
            <MainHeader />
            <div>
              <DivMain>
                <GettingStartedContainer>
                  <Title>Sign up and start building.</Title>
                  <IntroText>
                    <Icon>
                      <WebApiDbIcon style={{ height: "55px", width: "55px" }} />
                    </Icon>
                    <BlurbText>
                      Learn more about our APIs and what you need to get started
                    </BlurbText>
                  </IntroText>
                  <IntroText>
                    <Icon>
                      <ConfigurationIcon
                        style={{ height: "55px", width: "auto" }}
                      />
                    </Icon>
                    <BlurbText>
                      Create and test cards in a secure environment with no risk
                      to your systems
                    </BlurbText>
                  </IntroText>
                  <IntroText>
                    <Icon>
                      <IntegrationIcon
                        style={{ height: "55px", width: "55.32px" }}
                      />
                    </Icon>
                    <BlurbText>
                      Start building right away and scope out how our solutions
                      can work for you
                    </BlurbText>
                  </IntroText>
                </GettingStartedContainer>
                <div>
                  <Wrapper>
                    {!showConfirmation && (
                      <FormContainer>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <BlurbText style={{ fontWeight: "700" }}>
                            Sign up
                          </BlurbText>
                          <LabelInput>
                            <RequiredLabel label="First Name" />
                            <CustomStyledParent>
                              <StyledTextInput
                                aria-label="firstName"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e);
                                  isFormValidCheck();
                                }}
                                isValid={firstNameFormErrors}
                                errorMessage={firstNameFormErrors}
                                width="100%"
                                placeholder="First Name"
                                id='signupFirstName'
                              />
                            </CustomStyledParent>
                          </LabelInput>
                          <LabelInput>
                            <RequiredLabel label="Last Name" />
                            <CustomStyledParent>
                              <StyledTextInput
                                aria-label="lastName"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e);
                                  isFormValidCheck();
                                }}
                                isValid={lastNameFormErrors}
                                errorMessage={lastNameFormErrors}
                                width="100%"
                                placeholder="Last Name"
                                id='signupLastName'
                              />
                            </CustomStyledParent>
                          </LabelInput>

                          <LabelInput>
                            <RequiredLabel label="Business Email Address" />
                            <CustomStyledParent>
                              <StyledTextInput
                                aria-label="emailAddress"
                                value={emailAddress}
                                onChange={(e) => {
                                  setEmailAddress(e);
                                  isFormValidCheck();
                                }}
                                isValid={emailFormErrors}
                                errorMessage={emailFormErrors}
                                width="100%"
                                placeholder="Business Email Address"
                                id='signupEmail'
                              />
                            </CustomStyledParent>
                          </LabelInput>

                          <LabelInput>
                            <RequiredLabel label="Create Password" />
                            <CustomStyledParent>
                              <StyledPasswordInput
                                aria-label="password"
                                value={password}
                                onChange={(e) => {
                                  setPassword(e);
                                  isFormValidCheck();
                                }}
                                isValid={passwordFormErrors}
                                errorMessage={passwordFormErrors}
                                width="100%"
                                placeholder="Password.."
                                id='signupPassword'
                              />
                            </CustomStyledParent>
                            <StyledPasswordText>
                              {passwordComplexityMessage}
                            </StyledPasswordText>
                          </LabelInput>

                          <LabelInput>
                            <RequiredLabel label="Company" />
                            <CustomStyledParent>
                              <StyledTextInput
                                aria-label="company"
                                value={company}
                                onChange={(e) => {
                                  setCompany(e);
                                  isFormValidCheck();
                                }}
                                isValid={companyNameFormErrors}
                                errorMessage={companyNameFormErrors}
                                width="100%"
                                placeholder="Company"
                                id='signupCompany'
                              />
                            </CustomStyledParent>
                          </LabelInput>
                          <LabelInput>
                            <LabelText>
                              {" "}
                              Are you already a Thredd customer?{" "}
                            </LabelText>
                            <RadioGroup
                              gap={50}
                              aria-label="threddCustomer?"
                              onChange={(e) => {
                                onExistingCustomerCheck(e);
                                isFormValidCheck();
                              }}
                              orientation="horizontal"
                            >
                              <StyledRadioButton label="Yes" value="yes" id='signupYesRadio'/>
                              <StyledRadioButton label="No" value="no" id='signupNoRadio'/>
                            </RadioGroup>
                          </LabelInput>
                          {TACFormErrors && <Errors>{TACFormErrors}</Errors>}
                          {/*
                            TODO: Newsletter checkbox
                          */}
                          {/* <Checkbox onChange={(e) => setNewsLetterCheck(e)}>
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "15px",
                                padding: "10px 0 10px 0",
                              }}
                            >
                              Sign-up to our newsletter for the latest news and
                              insights from Thredd
                            </span>
                          </Checkbox> */}
                          <div style={{ maxWidth: "550px" }}>
                            <LegalText>
                              By submitting this form I give my consent to
                              Thredd using my personal information to send me
                              communications regarding Thredd's products,
                              events, reports and services.{" "}
                              <a href="https://www.thredd.com/privacy-policy/">
                                Privacy Policy.
                              </a>
                            </LegalText>
                          </div>
                          <StyledCustomButton
                            variant="primary"
                            label="Sign up"
                            size="slimFullWidth"
                            isDisabled={isSignUpButtonDisabled}
                            data-testid="signupSignUpButton"
                          />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18px",
                              color: "#2D3232",
                            }}
                          >
                            By signing up you agree to our{" "}
                            <Link href="/T&C" target="_blank">
                              Terms & Conditions
                            </Link>{" "}
                            & &nbsp;
                            <Link
                              href="https://www.thredd.com/terms-and-conditions"
                              target="_blank"
                            >
                              Privacy Policy
                            </Link>
                          </span>
                        </Form>
                        <hr></hr>
                        <StyledPargraphText>
                          Already have a Sandbox account?
                        </StyledPargraphText>
                        <StyledCustomButton
                          variant="primary"
                          label="Sign in"
                          size="slimFullWidth"
                          onClick={navigateToLogin}
                          data-testid="signupLoginButton"
                        />
                      </FormContainer>
                    )}
                    {showConfirmation && (
                      <FormContainer
                          style={{
                            justifyContent: "flex-start",
                            justifySelf: "start",
                            gap: "30px",
                          }}
                        >
                          <StyledText variant="heading3Bold">
                            An email is on its way!
                          </StyledText>
                          <div>
                            <StyledParagraph variant="paragraphMedium">
                              {confirmationMessage}
                            </StyledParagraph>
                            <EmailLink>{emailAddress}</EmailLink>
                          </div>
                          <StyledParagraph variant="paragraphMedium">
                            Please check your inbox to continue. This
                            verification link expires after 24 hours.
                          </StyledParagraph>
                          <StyledButton
                            variant="primary"
                            label="Sign in"
                            size="slimFullWidth"
                            onClick={navigateToLogin}
                            data-testid="signupConfirmationButton"
                          />
                        </FormContainer>
                    )}
                  </Wrapper>
                </div>
              </DivMain>
              {windowSize >= 1024 && <LightBluePattern />}
            </div>
          </Container>
        </div>

        <Footer footermode="light" />
      </MainContainer>
    </>
  );
};
