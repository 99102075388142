import {useState} from 'react'

export const useBubbleMessage = () => {
  const [isVisible, setIsVisible] = useState(false)

  const showMessageBubble = () => {
    if (isVisible) return
    setIsVisible(true)
    setTimeout(() => setIsVisible(false), 5000)
  }

  return {isVisible, showMessageBubble}
}
