import { Div } from "components";
import useWindowResize from "hooks/useWindowResize";
import { Footer } from "layouts/components/Footer/Footer";
import { MainHeader } from "layouts/components/MainHeader/MainHeader";
import styled from "styled-components";
import Container from "styled/Container";
import { media } from "utils/media";
import smallmaintenancePattern from "../assets/Images/maintanance-patter-small.svg";
import maintenancePattern from "../assets/Images/maintanance-pattern.svg";

const PageMaintenancePattern = styled(Div)`
  background: url(${process.env.PUBLIC_URL + maintenancePattern});
  width: 690px;
  position: absolute;
  top: 189px;
  height: 670px;

  ${media.lg`
    right: -30%;
  `};
  ${media.xl`
    right: 0;
  `}
`;

const PageSmallMaintenancePattern = styled(Div)`
  background: url(${process.env.PUBLIC_URL + smallmaintenancePattern});
  width: 60px;
  position: absolute;
  right: 5px;
  top: 131px;
  height: 345px;
  ${media.md`
    width: 80px;
    top: 131px;
    height: 460px;
  `}
`;

const PageMainDiv = styled.div`
  background: #ede6df;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  display: flex;
  height: 543px;
  position: relative;
  ${media.md`
    height: 551px;
  `};
  ${media.lg`
    height: 759px;
  `};
`;

const PageParagraph = styled.p`
  padding-bottom: 10px;
  ${media.lg`
    color: #2d3232;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.5px;

    padding-bottom: 30px;
  `}
`;

const PageStyledText = styled.p`
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  color: #2d3232;
  ${media.md`
    font-size: 48.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 48.5px;
    letter-spacing: -1px;
    max-width: 557px;
  `};

  ${media.lg`
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1px;
    max-width: 770px;
 `}
`;

const PageTextContainer = styled.div`
  width: 100%;
  padding-top: 138px;

  ${media.md`
    width: 557px;
    padding-top: 130px;
  `};
  ${media.lg`
    padding-top: 159px;
    width: 770px;
  `}
`;

const PageStyledParagraphText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
  padding-top: 20px;
  ${media.md`
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: -0.5px;
    padding-top: 20px;
  `}
  ${media.lg`
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.2px;
    letter-spacing: -0.5px;
    padding-top: 40px;
  `}
`;

export const PageNotFound = () => {
  const { width } = useWindowResize();

  return (
    <div>
      <PageMainDiv>
        <Container>
          <MainHeader />
          <PageTextContainer>
            <PageParagraph>Error 404</PageParagraph>
            <PageStyledText>Error 404</PageStyledText>
            <PageStyledParagraphText>
              The page you are looking for was not found.
            </PageStyledParagraphText>
          </PageTextContainer>
          {width < 1024 && <PageSmallMaintenancePattern />}
          {width > 1024 && <PageMaintenancePattern />}
        </Container>
      </PageMainDiv>
      <Footer footermode="light" />
    </div>
  );
};
