import styled from "styled-components";

const IframeWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const TermsAndConditions = () => {
  return (
    <IframeWrapper>
      <iframe
        src="TermsAndConditions.htm"
        style={{ height: "100vh", width: "100%" }}
      />
    </IframeWrapper>
  );
};
