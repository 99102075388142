import React, {ReactElement} from 'react'

import {Text} from 'components'
import {colors} from 'config/theme'
import _ from 'lodash'
import styled from 'styled-components'

export interface Props {
  label: string
  onClick: () => void
  isDisabled?: boolean
  icon?: ReactElement
  shouldUseStrokeField?: boolean
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 20px;
  color: ${colors.primaryBlue500};
  background: none;
  padding: 0;

  &:hover {
    color: ${colors.primaryBlue400};
  }

  &:active {
    color: ${colors.primaryBlue900};
  }

  &:disabled {
    color: ${colors.grey300};
  }
`

export const LinkButton = ({label, icon, onClick, isDisabled, shouldUseStrokeField}: Props) => {
  const id = `${_.camelCase(label)}LinkButton`
  const iconColor = shouldUseStrokeField ? {stroke: 'currentcolor'} : {fill: 'currentcolor'}
  const iconComponent = icon ? React.cloneElement(icon, iconColor) : null

  return (
    <StyledButton id={id} onClick={onClick} disabled={isDisabled}>
      {iconComponent}
      <Text fontType="buttonLink" opacity={1} color={'currentcolor'}>
        {label}
      </Text>
    </StyledButton>
  )
}
