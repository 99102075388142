import axios from "axios";

interface IMailInput {
  topic: string;
  message: string;
}


async function SendContactFormMessage(UserModel: IMailInput,token:string|null) {
 
  return axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}userManagement`,
    {
      query: `mutation($topic : String!, $message : String!){ 
                contactForm(emailContactFormDetailsDto: {
                    topic: $topic,
                    message : $message
              })
            }`,
      variables: {
        topic: UserModel.topic,
        message: UserModel.message,
      },
    },
    {
      headers: {
        'Authorization': `Bearer ${token}` ,// <-- your auth token here
        "Content-Type": "application/json",
      },
    }
  ) .then(() => {
    return "OK";
  })
  .catch((err: Error) => {
    throw {
      error: err,
    };
  });
}

const SendMail = {
    SendContactFormMessage,
};

export default SendMail;
