import { forwardRef, ReactElement, useImperativeHandle, useRef } from "react";

import { Column, Row, Text } from "components";
import { colors } from "config/theme";
import { camelCase } from "lodash";
import styled from "styled-components";

import { TextPropsWithType, textStyle } from "./Typography";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  width?: number;
  error?: boolean;
  type?: string;
  onFocus?: () => void;
};

const getBorderColor = (disabled?: boolean, error?: boolean) => {
  if (disabled) return colors.grey300;
  if (error) return colors.red900;
  return colors.grey200;
};

const InnerWrapper = styled(Row)<{
  disabled?: boolean;
  error?: boolean;
  width?: number;
}>`
  width: ${({ width }) => width ?? "100%"};
  height: 40px;
  padding: 8px 16px;
  background-color: ${({ disabled }) =>
    disabled ? colors.grey100 : colors.white};
  border: 1px solid ${({ disabled, error }) => getBorderColor(disabled, error)};
  ${({ disabled, error }) =>
    !disabled &&
    !error &&
    `&:hover:not(:focus-within) {
      border-color: ${colors.grey300};
    }`}
  &:focus-within {
    border-color: ${({ error }) =>
      error ? colors.red900 : colors.complimentaryBlue500};
  }
  cursor: ${({ disabled }) => (disabled ? "default" : "text")};
  box-sizing: border-box;
  gap: 12px;
  align-items: center;
`;
const IconWrapper = styled(Row)<{ disabled?: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const StyledInput = styled.input<TextPropsWithType & { error?: boolean }>`
  ${textStyle};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  border: 0;
  color: ${({ error }) => (error ? colors.red900 : colors.darkBlue900)};
  &:disabled {
    color: ${colors.grey300};
    background-color: ${colors.grey100};
  }
  caret-color: ${colors.primaryBlue500};
  ::placeholder {
    color: ${colors.grey300};
  }
  outline: none;
  ::-ms-reveal {
    display: none;
  }
`;

const Container = styled(Column)<{ width?: number }>`
  width: ${({ width }) => width ?? "100%"};
`;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }, ref) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const id = props.id ?? props.name ?? camelCase(props.label);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

    const handleClick = () => !props.disabled && innerRef.current?.focus();

    return (
      <Container width={props.width}>
        {!!props.label && (
          <Text
            id={`${id}Label`}
            fontType="inputLabel"
            color={colors.textPrimary}
            paddingLeft={16}
            style={{ padding: "0px 16px 8px 0px" }}
          >
            {props.label}
          </Text>
        )}
        <InnerWrapper
          width={props.width}
          disabled={props.disabled}
          error={props.error}
          onClick={handleClick}
        >
          {!!props.iconLeft && (
            <IconWrapper id={`${id}IconLeft`} disabled={props.disabled}>
              {props.iconLeft}
            </IconWrapper>
          )}
          <StyledInput
            id={`${id}Input`}
            type={props.type}
            fontType="inputContent"
            {...props}
            ref={innerRef}
            autoComplete="off"
          />
          {!!props.iconRight && (
            <IconWrapper id={`${id}IconRight`} disabled={props.disabled}>
              {props.iconRight}
            </IconWrapper>
          )}
        </InnerWrapper>
      </Container>
    );
  }
);
