import { FC, ReactElement, useRef, useState } from "react";

import { Column, Div, Line } from "components";
import { useOnClickOutside } from "config/hooks";
import { colors } from "config/theme";
import { camelCase } from "lodash";
import styled from "styled-components";
import { LayoutProps } from "styled-system";

import {
  DropDownButton,
  MiniDropDownButton,
} from "./components/DropdownButton";
import { MenuItem } from "./components/MenuItem";

const DropdownWrapper = styled.div`
  display: flex;
  position: relative;
`;

const ItemsWrapper = styled(Column)<{ isExpanded: boolean }>`
  width: max(100%, 142px);
  display: ${(props) => (props.isExpanded ? "block" : "none")};
  position: absolute;
  top: calc(100% + 2px);
  right: 0%;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${colors.white};
  padding: 0px 16px;
`;

interface DropdownProps extends LayoutProps {
  id?: string;
  text: string;
  items: {
    id?: string;
    text: string;
    icon?: ReactElement;
    onClick?: () => void;
  }[];
  disabled?: boolean;
}

export const DropdownMenu: FC<DropdownProps> = ({
  id,
  text,
  items,
  disabled,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleSetExpanded = (newState: boolean) => {
    if (disabled) return;
    setIsExpanded(newState);
  };

  useOnClickOutside(wrapperRef, () => {
    handleSetExpanded(false);
  });

  const handleItemClick = (onClick?: () => void) => {
    handleSetExpanded(false);
    onClick?.();
  };

  const buttonId = id ?? camelCase(text);
  return (
    <DropdownWrapper ref={wrapperRef}>
      {text !== "" && (
        <DropDownButton
          id={buttonId}
          text={text}
          isExpanded={isExpanded}
          disabled={disabled}
          onClick={() => handleSetExpanded(!isExpanded)}
        />
      )}
      {text === "" && (
        <MiniDropDownButton
          id={buttonId}
          isExpanded={isExpanded}
          onClick={() => handleSetExpanded(!isExpanded)}
        />
      )}
      <ItemsWrapper isExpanded={isExpanded}>
        {items.map((it, index) => (
          <Div key={index}>
            {index > 0 ? <Line /> : null}
            <MenuItem
              id={it.id ?? `${buttonId}Item${index}`}
              icon={it.icon}
              text={it.text}
              onClick={() => handleItemClick(it.onClick)}
            />
          </Div>
        ))}
      </ItemsWrapper>
    </DropdownWrapper>
  );
};
