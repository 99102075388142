import {Navigate} from 'react-router-dom'

import {useAuth} from 'oidc-react'

export const PostLogin = () => {
  const {userData} = useAuth()
  const isLoggedIn = userData != null

  if (isLoggedIn) return <Navigate to={'/'} />

  return <h1>Loading</h1>
}
