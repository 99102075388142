import { MainLayout } from "layouts/MainLayout";
import { Home, PageNotFound } from "routes";

export const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { index: true, element: <Home /> },
      { path: "*", element: <PageNotFound /> },
    ],
  },
];
