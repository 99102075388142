import { Div } from "components";
import { ArrowRight, Text } from "gps-design-system";
import useWindowResize from "hooks/useWindowResize";
import { useAuth } from "oidc-react";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { media } from "utils/media";
import { ProfileStatus } from "../Header/components/ProfileStatus";

const Header = styled(Div)`
  display: flex;
  width: 100%;
  font-family: "grotesk", px-grotesk;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: "16px";
  padding-right: "16px";
  ${media.sm`
    width: 100%;
  `};

  ${media.md`
    width: 100%
  `};

  ${media.lg`
    width: 100%
  `};

  ${media.xl`
    width: 1280px;
  `};
  margin: auto;
`;

const ItemsContainerHeader = styled(Div)`
  position: absolute;
  top: 9px;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255);
  height: 66.8px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 60px;
  ${media.lg`
    padding: 10px 24px;
    top: 22px;
  `}
`;

const Logo = styled.img`
  width: 107px;
  height: 23px;
  @media only screen and (max-width: 640px) {
    width: 81px;
    height: auto;
    padding-left: 4px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  @media only screen and (max-width: 640px) {
    gap: 20px;
  }
`;

const ContactButton = styled(Div)`
  color: #2d3232 !important;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 5px;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: underline;
  }
`;

const HeaderLink = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledText = styled(Text)`
  font-size: 16px;
`;

const HeaderWrapper = styled.div``;

export const MainHeader = (props: any) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const isMobile = useWindowResize();
  const isSignedIn = props.isSignedIn;

  const scrollToContactForm = () => {
    const contactFormElement = document.getElementById("contactForm");
    if (contactFormElement) {
      contactFormElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const signOut = () => {
    auth
      .signOutRedirect()
      .then(() => {
        queryClient.clear(); // clears cache
        localStorage.clear();
      })
      .catch((err) => console.error("Failed to signOut: ", err));
  };

  return (
    <HeaderWrapper>
      <Header>
        <ItemsContainerHeader>
          <a href="https://www.thredd.com/">
            <Logo src="/Thredd_Logo_Indigo_RGB.svg" />
          </a>
          <ProfileContainer>
            {isMobile.width >= 640 && isSignedIn && (
              <ContactButton onClick={scrollToContactForm}>
                <StyledText variant="paragraphMediumBold">
                  Talk to an expert
                </StyledText>
                <ArrowRight />
              </ContactButton>
            )}
            {isSignedIn && <ProfileStatus onSignOut={signOut} />}
          </ProfileContainer>
        </ItemsContainerHeader>
      </Header>
    </HeaderWrapper>
  );
};
