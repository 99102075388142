import { CopyIcon } from "assets/icons";
import { MOBILE_WIDTH } from "config/constants";
import { Button, Text } from "gps-design-system";
import styled from "styled-components";
import { media } from "utils/media";
import { Div } from "./Wrappers";

const SandboxDetailsWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 20px;
  background: white;
  justify-content: space-between;
  height: 368px;

  ${media.sm`
    height: 368px;
    padding: 48px 48px;
  `}
  ${media.lg`
    height: 445px;
    margin-top: -30px;
    padding: 46px 48px;
    width: 516px;
  `}
`;

const SandboxDetail = styled(Div)`
  display: flex;
  align-items: center;
  word-break: break-all;
  justify-content: flex-start;
  padding-bottom: 28px;
`;

const HeaderWrapper = styled(Div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  position: relative;
  padding-bottom: 20px;
  ${media.lg`
    padding-bottom: 40px;
  `}
`;

const IconWrapper = styled.span`
  padding-left: 10px;
  font-size: 22px;
`;

const ButtonWrapper = styled(Div)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin-top: 25px;
  }
`;

const InfoText = styled(Text)`
  max-width: 80%;
  font-size: 16px;
  font-weight: 400;
  font-family: "grotesk", px-grotesk, Arial, sans-serif;
  letter-spacing: -0.5px;
  color: #2d3232;
  ${media.md`
    max-width: 100%;
    font-size: 17px;
  `}
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0px;
  color: #2d3232;
  ${media.lg`
    font-size: 26px;
 `}
`;

const Label = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 8px;
  letter-spacing: 0px;
  text-align: left;
  color: #2d3232;

  ${media.md`
    font-size: 18px;
  `}
`;

const StyledCopyIcon = styled(CopyIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  padding: 10px;
  height: 47px;
`;

const InfoTextLink = styled(Text)`
  color: #251d97;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: -0.5px;
  ${media.md`
  max-width: 100%;
  font-size: 17px;
`}
`;

interface Props {
  clientId: string;
  clientExists: boolean;
  copyApiUrl: () => void;
  copyClientID: () => void;
  createNewSecretKey: () => void;
}

export const SandboxAccessInformation = ({
  clientId,
  clientExists,
  copyApiUrl,
  copyClientID,
  createNewSecretKey,
}: Props) => {
  return (
    <SandboxDetailsWrapper>
      <article>
        <HeaderWrapper>
          <Title>Access Information</Title>
        </HeaderWrapper>

        <Label>Sandbox (URL)</Label>
        <SandboxDetail>
          <InfoTextLink variant="paragraphMedium" id='homeUrlLink'>
            https://sandbox.globalprocessing.com/sandbox/api/v1
          </InfoTextLink>
          <IconWrapper id='homeUrlLinkCopy' onClick={copyApiUrl}>
            <StyledCopyIcon />
          </IconWrapper>
        </SandboxDetail>

        <Label>Client ID</Label>
        <SandboxDetail>
          <InfoTextLink variant="paragraphMedium" id='homeClientId'>{clientId}</InfoTextLink>
          <IconWrapper id='homeClientIdCopy' onClick={copyClientID}>
            <StyledCopyIcon />
          </IconWrapper>
        </SandboxDetail>
      </article>
      <ButtonWrapper>
        <StyledButton
          label="Generate Secret Key"
          variant="primary"
          size="slimFullWidth"
          onClick={createNewSecretKey}
          data-testid='homeGenerateSecretKeyButton'
        />
      </ButtonWrapper>
    </SandboxDetailsWrapper>
  );
};
