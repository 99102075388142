import { Color, colors } from "config/theme";
import styled, { css } from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";

type StyledProps = TypographyProps & SpaceProps;

export type TextProps = StyledProps & {
  color?: Color;
  opacity?: number;
  noSelect?: boolean;
};

export type FontTypes =
  | "heading1"
  | "heading2"
  | "heading3"
  | "heading4"
  | "heading5"
  | "heading6"
  | "heading7"
  | "body1Bold"
  | "body1Semibold"
  | "body1Regular"
  | "body1Small"
  | "body2Bold"
  | "body2Semibold"
  | "body3Semibold"
  | "button1"
  | "button2"
  | "buttonLink"
  | "inputLabel"
  | "inputContent"
  | "mobileHeading"
  | "mobileHeadingSmall"
  | "paragraph01"
  | "paragraph02"
  | "paragraph03"
  | "paragraphLargeBold"
  | "paragraphMediumRegular"
  | "paragraphMediumSemibold"
  | "disabledButton";

export type TextPropsWithType = TextProps & {
  fontType?: FontTypes;
};

const DEFAULT_FONT_FAMILY = '"grotesk", px-grotesk';

export const fontStyles: { [key in FontTypes]: TextProps } = {
  heading1: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 40,
    lineHeight: 48,
    letterSpacing: "-0.01em",
    color: colors.textPrimary,
  },
  heading2: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 36,
    lineHeight: 43,
    color: colors.textPrimary,
  },
  heading3: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 32,
    lineHeight: 38,
    color: colors.textPrimary,
  },
  heading4: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 28,
    lineHeight: 34,
    color: colors.textPrimary,
  },
  heading5: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 24,
    lineHeight: 29,
    color: colors.textPrimary,
  },
  heading6: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 28,
    lineHeight: 24,
    color: colors.textPrimary,
  },
  heading7: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 19,
    lineHeight: 23,
    color: colors.textPrimary,
  },
  body1Bold: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    color: colors.darkBlue900,
  },
  body1Semibold: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 20,
    color: colors.darkBlue900,
  },
  body1Regular: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    color: colors.darkBlue900,
  },
  body1Small: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 10.5,
    lineHeight: 20,
    color: colors.grey500,
  },
  body2Bold: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: "0.02em",
    color: colors.darkBlue900,
  },
  body2Semibold: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: "0.02em",
    color: colors.grey500,
  },
  body3Semibold: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 10,
    lineHeight: 16,
    letterSpacing: "0.05em",
    color: colors.grey500,
  },
  button1: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 24,
    color: colors.white,
  },
  button2: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 17,
    color: colors.white,
  },
  buttonLink: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    color: colors.white,
  },
  inputLabel: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: "0.02em",
    color: colors.textPrimary,
  },
  inputContent: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 24,
    color: colors.darkBlue900,
  },
  mobileHeading: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 24,
    lineHeight: 40,
    color: colors.darkBlue900,
    textAlign: "center",
  },
  mobileHeadingSmall: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 20,
    lineHeight: 24,
    color: colors.textPrimary,
  },
  paragraph01: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 20,
    lineHeight: 24,
    color: colors.textPrimary,
  },
  paragraph02: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 19,
    color: colors.textPrimary,
  },
  paragraph03: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 16,
    color: colors.textPrimary,
  },
  paragraphLargeBold: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 22,
    color: colors.textPrimary,
  },
  paragraphMediumRegular: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 19,
    color: colors.textPrimary,
  },
  paragraphMediumSemibold: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 24,
    color: colors.textPrimary,
  },
  disabledButton: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 24,
    color: colors.grey300,
  },
};

const getFontStyle = (fontType: FontTypes) => {
  const style = fontStyles[fontType];
  return css`
    font-family: ${style.fontFamily};
    font-style: ${style.fontStyle};
    font-weight: ${String(style.fontWeight)};
    font-size: ${String(style.fontSize)}px;
    line-height: ${String(style.lineHeight)}px;
    text-align: ${String(style.textAlign)};
    color: ${style.color};
    ${style.opacity &&
    css`
      opacity: ${style.opacity};
    `}
    ${style.letterSpacing &&
    css`
      letter-spacing: ${String(style.letterSpacing)};
    `}
  `;
};

export const textStyle = css<TextPropsWithType>`
  white-space: pre-wrap;

  ${({ fontType }) =>
    css`
      ${getFontStyle(fontType || "body1Bold")}
    `}
  ${({ color }) =>
    !!color &&
    css`
      color: ${color};
    `}
  ${({ opacity }) =>
    !!opacity &&
    css`
      opacity: ${opacity};
    `}
  ${(props) =>
    props.noSelect &&
    css`
      user-select: none;
    `}
  ${space}
  ${typography}
`;

export const Text = styled.p.attrs({ margin: 0 })`
  ${textStyle}
`;
