import axios from "axios";

async function regenerateSecretKey(
  token: string | null,
  clientId: string | null
) {
  return axios
    .post(
      `${process.env.REACT_APP_SANDBOX_API_ENDPOINT}sandbox`,
      {
        query: `mutation($clientId: String!){ 
            regenerateSecretKey(clientId: $clientId)
            }`,

        variables: {
          clientId: clientId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // <-- your auth token here
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err: Error) => {
      throw {
        error: err,
      };
    });
}

const SecretKey = {
  regenerateSecretKey,
};

export default SecretKey;
