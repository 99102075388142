import { AuthContextProps } from "oidc-react";
import { QueryClient } from "react-query";

interface ResponseValue {
  isError: boolean;
  isWarning: boolean;
  message: string;
}

function HandleResponseErrors(
  err: any,
  auth: AuthContextProps,
  queryClient: QueryClient,
  message?: string
): ResponseValue {
  let responseValue: ResponseValue = {
    isError: false,
    isWarning: false,
    message: "",
  };

  switch (err.error?.response?.status) {
    case 400:
    case 404:
    case 403: {
      responseValue.isError = true;
      responseValue.message = " " + err.error.message;
      return responseValue;
    }
    case 401: {
      responseValue.isWarning = true;
      responseValue.message =
        "Your authentication has been expired. \n Please sign in again to continue.";
      setTimeout(() => {
        auth
          .signOutRedirect()
          .then(() => {
            queryClient.clear(); // clears cache
            localStorage.clear();
          })
          .catch((err) => console.error("Failed to signOut: ", err));
      }, 5000);
      return responseValue;
    }
    default: {
      responseValue.isError = true;
      responseValue.message = message
        ? message
        : "Something went wrong. Please try again later.";
      return responseValue;
    }
  }
}

const ResponseErrorHandler = {
  HandleResponseErrors,
};

export default ResponseErrorHandler;
