import axios from "axios";

export class SandboxResponse {
  sandboxExists: boolean;
  clientId: string;
  clientExists: boolean;

  constructor(sandboxExists: boolean, clientId: string, clientExists: boolean) {
    this.sandboxExists = sandboxExists;
    this.clientId = clientId;
    this.clientExists = clientExists;
  }
}

async function CheckSandboxStatus(
  userId: string | undefined,
  token: string | null
) {
  return axios
    .post(
      `${process.env.REACT_APP_SANDBOX_API_ENDPOINT}sandbox`,
      {
        query: `query($userId: UUID!) {
                    sandboxStatus(userId: $userId) {              
                      sandboxExists,
                      clientId,
                      clientName,
                      clientExists                  
                    }                 
                  }`,
        variables: {
          userId: userId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((resp) => {
      let returnObj: SandboxResponse = {
        sandboxExists: resp.data.data.sandboxStatus.sandboxExists,
        clientId: resp.data.data.sandboxStatus.clientId,
        clientExists: resp.data.data.sandboxStatus.clientExists
      };

      return returnObj;
    })
    .catch((err: Error) => {
      throw {
        error: err,
      };
    });
}

const SandboxServices = {
  CheckSandboxStatus,
};

export default SandboxServices;
