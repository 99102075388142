import {createGlobalStyle} from 'styled-components'

import {scrollBarStyles} from './scrollbar'
import {selectStyles} from './select'
import {slideModalStyles} from './slideModal'
import {virtualizedTableStyles} from './virtualizedTable'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ''grotesk', px-grotesk;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    * {
      font-family: 'grotesk', px-grotesk;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  
  // #root{
  //   height: 100vh;
  // }

  button {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 0;
    font-weight: normal;
    font-size: inherit;
    text-decoration: none;
    cursor: pointer;
    font-size: 100%;
    outline: none;
  }
  ${selectStyles}
  ${scrollBarStyles}
  ${virtualizedTableStyles}
  ${slideModalStyles}
`
