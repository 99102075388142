import axios from "axios";

interface IFormInputs {
  firstName: string;
  lastName: string;
  password: string;
  companyName: string;
  email: string;
  userName: string;
  termsAndConditions: boolean;
  existingCustomer: boolean
}

async function SubmitFormData(UserModel: IFormInputs) {
  return await axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}userManagement`,
      {
        query: `mutation($firstName : String!, $lastName : String!, $userEmail : String!, $password : String!,
            $companyName : String!, $termsAndConditions:Boolean!, $existingCustomer:Boolean!){ 
              registerUser(userRegistrationDetails: {
                 firstName: $firstName,
                 surname : $lastName,              
                 userPassword : $password,           
                 companyName : $companyName,
                 userEmail : $userEmail,                     
                 termsAndConditions: $termsAndConditions
                 existingCustomer: $existingCustomer       
              })
            }`,
        variables: {
          firstName: UserModel.firstName,
          lastName: UserModel.lastName,
          userEmail: UserModel.email,
          password: UserModel.password,
          companyName: UserModel.companyName,
          termsAndConditions: UserModel.termsAndConditions,
          existingCustomer: UserModel.existingCustomer
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      return "OK";
    })
    .catch((err: Error) => {
      throw {
        error: err,
      };
    });
}

const Account = {
  SubmitFormData,
};

export default Account;
