import { useQueryClient } from "react-query";
import { Div, Row } from "components";
import { HEADER_HEIGHT, MOBILE_WIDTH } from "config/constants";
import { Breadcrumbs } from "layouts/components/Breadcrumbs";
import { useAuth } from "oidc-react";
import styled from "styled-components";

import { ProfileStatus } from "./components/ProfileStatus";
import { colors } from "config/theme";

const Container = styled(Row)`
  z-index: 10;
  padding-left: 40px;
  padding-right: 40px;
  height: ${HEADER_HEIGHT}px;
  justify-content: space-between;
  align-items: center;
  background: ${colors.white};
  display: flex;
  @media (max-width: ${MOBILE_WIDTH}) {
    z-index: 0;
  }
`;

const InputContainer = styled(Div)`
  margin-left: auto;
  padding-right: 20px;
`;

export const Header = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();

  const signOut = () => {
    auth
      .signOutRedirect()
      .then(() => {
        queryClient.clear(); // clears cache
        localStorage.clear();
      })
      .catch((err) => console.error("Failed to signOut: ", err));
  };

  return (
    <Container>
      <Breadcrumbs />
      <ProfileStatus onSignOut={signOut} />
    </Container>
  );
};
