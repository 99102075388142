import { Navigate, useRoutes } from "react-router-dom";

import { useAuth } from "oidc-react";

import { routes } from "./routes";

export const MainNavigator = () => {
  const { userData, isLoading } = useAuth();
  const routing = useRoutes(routes);
  const isLoggedIn = userData != null;

  if (isLoading) return null;
  if (!isLoggedIn) return <Navigate to={"/login"} />;

  return routing;
};
