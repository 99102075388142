import ResponseErrorHandler from "Services/ResponseErrorHandler";
import ConfirmationServices from "Services/confirmationCode";
import Background from "assets/Images/watchBg.png";
import { RightVectorIcon } from "assets/icons";
import { BubbleMessage, Column, Div, useBubbleMessage } from "components";
import { colors, theme } from "config/theme";
import { Button, Text } from "gps-design-system";
import { Footer } from "layouts/components/Footer/Footer";
import { MainHeader } from "layouts/components/MainHeader/MainHeader";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyle } from "styles";
import { getIsMobileMode } from "utils/mobile";
import lightBluePattern from "../assets/Images/lightBluePattern.svg";

const Wrapper = styled(Column)`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 32px;
  height: fit-content;
  max-width: 540px;
  margin-top: 10%;
  gap: 25px;
`;

const ContainerImg = styled(Div)`
  display: flex;
  height: 100%;
  z-index: 1;
  margin-top: 100px;
  margin-left: 5%;
  @media only screen and (max-width: 640px) {
    background-image: none;
    width: auto;
    height: auto;
  }
`;

const DummyFormContainer = styled(Div)`
  margin-top: 10%;
  margin-left: 20%;
  @media only screen and (max-width: 640px) {
    margin: 154px 40px 30px 20px;
  }
`;

const DummyFormInput = styled(Div)`
  background: linear-gradient(
    90deg,
    rgba(181, 196, 201, 0.5) 0%,
    rgba(181, 196, 201, 0.2) 100%
  );
  border-radius: 25px;
`;

const ContentContainer = styled(Div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #ede6df;
  max-width: 1300px;
  margin: auto;
  margin-bottom: 250px;
  @media only screen and (max-width: 640px) {
    background-image: none;
    width: auto;
    height: auto;
    flex: 1 0 auto;
  }
`;

const DivMain = styled(Div)`
  background: #ede6df;
  height: 100%;
  @media only screen and (max-width: 640px) {
    height: 100%;
    display: flex;
    justify-content: left;
    flex-direction: column;
  }
`;

const ContentHolder = styled(Div)`
  background: ${colors.background};
  overflow-y: auto;
  display: block;
  align-items: center;
  @media only screen and (max-width: 640px) {
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
  }
`;

const ContentCard = styled(Div)`
  background: ${colors.white};
  padding: 30px 20px;
  @media only screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const Img = styled.img`
  height: 601px;
  width: 671px;
  align-self: center;
`;

const LightBluePattern = styled(Div)`
  background: url(${process.env.PUBLIC_URL + lightBluePattern});
  width: 120px;
  height: 808px;
  margin-top: 100px;
  display: block;
  position: absolute;
  top: 145px;
  right: 0;
`;

const Container = styled(Div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ede6df;
  @media only screen and (max-width: 640px) {
    margin-bottom: 20px;
  }
`;

const StyledParagraph = styled(Text)`
  font-size: 15px;
  line-height: 18px;
`;

const rightVectorIcon = () => {
  return <RightVectorIcon />;
};

export const Confirmation = () => {
  const [confirmationCodeStatus, setConfirmationCodeStatus] =
    useState<string>("loading");
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const { isVisible, showMessageBubble } = useBubbleMessage();
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isWarning] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const verificationCode = queryParams.get("code");
  const userEmail = queryParams.get("email");
  const isMobile = getIsMobileMode();
  const auth = useAuth();
  const queryClient = useQueryClient();

  useEffect(() => {
    ConfirmationServices.CheckConfirmationCode(verificationCode, userEmail)
      .then((resp) => {
        if (resp == "OK") {
          setConfirmationCodeStatus("passed");
          location.replace(process.env.REACT_APP_CLIENT + "/login");
        }
      })
      .catch((err) => {
        setConfirmationCodeStatus("failed");
        showMessageBubble();
        const response = ResponseErrorHandler.HandleResponseErrors(
          err,
          auth,
          queryClient
        );
        setIsError(response.isError);
        setMessage(response.message);
      });
  }, []);

  const resendVerificationEmail = () => {
    ConfirmationServices.ResendVerificationCode(userEmail)
      .then((resp) => {
        if (resp == "OK") {
          setShowSuccessMessage(true);
          setTimeout(() => {
            location.replace(process.env.REACT_APP_CLIENT + "/login");
          }, 5000);
        }
      })
      .catch((err) => {
        showMessageBubble();
        const response = ResponseErrorHandler.HandleResponseErrors(
          err,
          auth,
          queryClient
        );
        setIsError(response.isError);
        setMessage(response.message);
      });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BubbleMessage
          isVisible={isVisible}
          message={message}
          isWarning={isWarning}
          isError={isError}
        />
        <Container>
          <MainHeader />
          <DivMain>
            {confirmationCodeStatus === "loading" && (
              <>
                <ContentContainer>
                  <ContainerImg style={isMobile ? { display: "none" } : {}}>
                    <Img alt="paypoint" src={Background}></Img>
                  </ContainerImg>
                  <DummyFormContainer>
                    <DummyFormInput
                      style={{
                        height: "24px",
                        width: "93px",
                        marginBottom: "20px",
                      }}
                    />
                    <DummyFormInput
                      style={{
                        height: "16px",
                        width: "328px",
                        marginBottom: "30px",
                      }}
                    />
                    <DummyFormInput
                      style={{
                        height: "8px",
                        width: "80px",
                        marginBottom: "3px",
                        marginLeft: "15px",
                      }}
                    />
                    <DummyFormInput
                      style={{
                        height: "40px",
                        width: "360px",
                        marginBottom: "20px",
                      }}
                    />
                    <DummyFormInput
                      style={{
                        height: "8px",
                        width: "80px",
                        marginBottom: "3px",
                        marginLeft: "15px",
                      }}
                    />
                    <DummyFormInput
                      style={{
                        height: "40px",
                        width: "360px",
                        marginBottom: "20px",
                      }}
                    />
                    <DummyFormInput
                      style={{
                        height: "40px",
                        width: "360px",
                        marginBottom: "20px",
                      }}
                    />
                    <DummyFormInput
                      style={{
                        height: "16px",
                        width: "150px",
                        marginBottom: "75px",
                      }}
                    />
                    <DummyFormInput
                      style={{ height: "40px", width: "360px" }}
                    />
                  </DummyFormContainer>
                  {!isMobile && <LightBluePattern />}
                </ContentContainer>
              </>
            )}
            {confirmationCodeStatus == "failed" && (
              <>
                {isMobile && (
                  <ContentHolder>
                    <ContentCard>
                      <Text variant="paragraphLargeBold">
                        Expired Verification Link
                      </Text>
                      {!showSuccessMessage && (
                        <>
                          <StyledParagraph variant="paragraphMedium">
                            Your verification message has been expired.
                          </StyledParagraph>
                          <StyledParagraph variant="paragraphMedium">
                            If you have requested access to the Thredd Developer
                            portal, click the button below to ask for a new
                            verification message.
                          </StyledParagraph>
                          <Button
                            label="Resend Email"
                            onClick={resendVerificationEmail}
                            variant="primary"
                            size="slimFullWidth"
                          />
                        </>
                      )}
                      {showSuccessMessage && (
                        <>
                          <Text variant="paragraphMedium">
                            Thank you. If we have an account matching your email
                            address, you will receive an email with a link to
                            confirm e-mail
                          </Text>
                        </>
                      )}
                    </ContentCard>
                  </ContentHolder>
                )}

                {!isMobile && (
                  <>
                    <ContentContainer>
                      <ContainerImg>
                        <Img
                          style={isMobile ? { display: "none" } : {}}
                          alt="paypoint"
                          src={Background}
                        ></Img>
                      </ContainerImg>
                      <Wrapper>
                        <Text variant="heading3Bold">
                          Expired Verification Link
                        </Text>
                        {!showSuccessMessage && (
                          <>
                            <Text variant="paragraphLarge">
                              Your verification message has been expired.
                            </Text>
                            <Text variant="paragraphLarge">
                              If you have requested access to the Thredd
                              Developer portal, click the button below to ask
                              for a new verification message.
                            </Text>
                            <Button
                              label="Resend Email"
                              onClick={resendVerificationEmail}
                              variant="primary"
                              size="slimFullWidth"
                            />
                          </>
                        )}
                        {showSuccessMessage && (
                          <>
                            <Text variant="paragraphLargeBold">
                              Thank you. If we have an account matching your
                              email address, you will receive an email with a
                              link to confirm e-mail
                            </Text>
                          </>
                        )}
                      </Wrapper>

                      {!isMobile && <LightBluePattern />}
                    </ContentContainer>
                  </>
                )}
              </>
            )}
            <Footer footermode="light" />
          </DivMain>
        </Container>
      </ThemeProvider>
    </>
  );
};
