import {
  css,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
} from "styled-components";

type MediaQuery = (
  strings: TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
) => FlattenSimpleInterpolation;

export const media: Record<string, MediaQuery> = {
  xs: (strings, ...interpolations) => css`
    @media (max-width: 575px) {
      ${css(strings, ...interpolations)};
    }
  `,
  sm: (strings, ...interpolations) => css`
    @media (min-width: 576px) {
      ${css(strings, ...interpolations)};
    }
  `,
  md: (strings, ...interpolations) => css`
    @media (min-width: 768px) {
      ${css(strings, ...interpolations)};
    }
  `,
  lg: (strings, ...interpolations) => css`
    @media (min-width: 1024px) {
      ${css(strings, ...interpolations)};
    }
  `,
  xl: (strings, ...interpolations) => css`
    @media (min-width: 1280px) {
      ${css(strings, ...interpolations)};
    }
  `,
};
