import React, { ReactElement } from "react";

import { Text } from "components";
import { colors } from "config/theme";
import _ from "lodash";
import styled from "styled-components";

type LabelProps = { label: string };
type IconProps = { icon: ReactElement };

type Props = {
  label?: string;
  icon?: ReactElement;
  onClick: () => void;
  isDisabled?: boolean;
} & (LabelProps | IconProps);

const StyledButton = styled.button<{ isOnlyIcon: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  border: 1px solid ${colors.darkBlue900};
  width: fit-content;
  padding: ${({ isOnlyIcon }) => (isOnlyIcon ? "7px" : "7px 15px")};

  &:active {
    background: ${colors.grey200};
  }
  &:disabled {
    background: ${colors.white};
    border: 1px solid ${colors.grey300};
    cursor: default;
  }
`;

export const TertiaryButton = ({ label, icon, onClick, isDisabled }: Props) => {
  const isOnlyIcon = !label && !!icon;

  const iconComponent = icon
    ? React.cloneElement(icon, {
        fill: colors.darkBlue900,
      })
    : null;

  const fontColor = isDisabled ? colors.grey300 : colors.darkBlue900;

  return (
    <StyledButton
      id={`${_.camelCase(label)}TertiaryButton`}
      onClick={onClick}
      disabled={isDisabled}
      isOnlyIcon={isOnlyIcon}
    >
      {!!icon && iconComponent}
      {!!label && (
        <Text fontType="button2" color={fontColor}>
          {label}
        </Text>
      )}
    </StyledButton>
  );
};
