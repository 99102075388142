import React, { ReactElement, useRef } from "react";
import Modal from "react-modal";

import { MAIN_LAYOUT_ID, LEFT_MENU_WIDTH } from "config/constants";
import { useOnClickOutside } from "config/hooks";
import { colors, shadows } from "config/theme";
import { rgba } from "utils";
import { getIsMobileMode } from "utils/mobile";

const isMobileMode = getIsMobileMode();

const overlay: React.CSSProperties = {
  backgroundColor: rgba(colors.grey500, 0.5),
  left: 0,
  backdropFilter: "blur(2px)",
  WebkitBackdropFilter: "blur(2px)",
  display: "flex",
  justifyContent: "flex-end",
};

const getContentStyle = (
  scrollBarWidth: number,
  height: number
): React.CSSProperties => ({
  top: "30%",
  width: isMobileMode ? `90%` : `width: calc(90% + 5px)`,
  boxSizing: "border-box",
  height: `${height ? height + "%" : "fit-content"}`,
  background: colors.white,
  boxShadow: shadows.bigBox,
  position: "unset",
  padding: "0px",
  margin: "auto",
  borderRadius: "0px",
  border: "none"
});

export interface SquareModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
  height?: any;
}

interface Props extends SquareModalProps {
  children: ReactElement;
}

export const SquareModal = ({
  isModalVisible,
  closeModal,
  children,
  height,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, closeModal);

  const mainLayout = document.getElementById(MAIN_LAYOUT_ID);
  const scrollBarWidth = mainLayout
    ? mainLayout.offsetWidth - mainLayout.clientWidth
    : 0;

  return (
    <Modal
      id="squareModal"
      style={{ overlay, content: getContentStyle(scrollBarWidth, height) }}
      isOpen={isModalVisible}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
    >
      {children}
    </Modal>
  );
};
