import { FontTypes, Row, Text } from "components";
import { colors } from "config/theme";
import _ from "lodash";
import { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  label: string;
  onClick?: () => void;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  icon?: ReactElement;
  fontType?: FontTypes;
  tabIndex?: number;
  id?: string;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  height: 48px;
  background: ${colors.primaryBlue500};
  width: fit-content;
  border: none;
  &:hover {
    background: ${colors.linksHover};
    border: none;
  }
  &:focus {
    background: ${colors.primaryBlue400};
    border: none;
  }
  &:active {
    background: ${colors.complimentaryBlue500};
    border: none;
    box-shadow: none;
  }
  &:disabled {
    background: rgba(163, 163, 163, 0.3);
    border: none;
    box-shadow: none;
    cursor: default;
  }
`;
const Icon = styled(Row)<{ disabled?: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const ButtonText = styled(Text)`
  padding-right: 10px;
`;

export const PrimaryButton = ({
  label,
  onClick,
  isDisabled,
  style,
  icon,
  fontType,
  tabIndex,
  id,
}: Props) => (
  <StyledButton
    id={id ? id : `${_.camelCase(label)}PrimaryButton`}
    disabled={isDisabled}
    onClick={onClick}
    style={style}
    tabIndex={tabIndex}
  >
    <ButtonText
      fontType={fontType ? fontType : "button1"}
      style={isDisabled ? { color: colors.grey300 } : { color: colors.white }}
    >
      {label}
    </ButtonText>
    {icon}
  </StyledButton>
);
