const getIsMobileMode = () => {
  const { innerWidth: width } = window;
  if (width <= 640) {
    return true;
  } else {
    return false;
  }
};

export { getIsMobileMode };
