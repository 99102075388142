import ResponseErrorHandler from "Services/ResponseErrorHandler";
import SecretKey from "Services/regenerateSecretKey";
import SandboxServices from "Services/sandboxStatus";
import { BubbleMessage, Div, useBubbleMessage } from "components";
import { InformationCard } from "components/InformationCard";
import { PatternContainer } from "components/PatternContainer";
import { SandboxAccessInformation } from "components/SandboxAccessInformation";
import { MOBILE_WIDTH } from "config/constants";
import { Button, Text } from "gps-design-system";
import { ContactForm } from "layouts/components/ContactForm/ContactForm";
import { Footer } from "layouts/components/Footer/Footer";
import { MainHeader } from "layouts/components/MainHeader/MainHeader";
import NewSecretKey from "layouts/components/Modals/NewSecretKey";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import Container from "styled/Container";
import { GlobalStyle } from "styles";
import { media } from "utils/media";
import { getIsMobileMode } from "utils/mobile";
import steps from "../assets/Images/steps.png";
import verticalGreen from "../assets/Images/verticalGreen.png";

const DivMain = styled(Div)`
  display: flex;
  flex-direction: column;
  background: transparent;
  background-color: #f6f2ef;
  width: 100%;
  position: relative;
`;

const Img = styled.img`
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  top: auto;
  z-index: 0;

  ${media.md`
    display: block;
    right: -210px;
    bottom: -50px;
  `}

  ${media.lg`
    display: block;
    right: -10%;
    bottom: 0;
  `}

  ${media.xl`
    display: block;
    right:0;
    bottom: 0;
  `}
`;

const Intro = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 577px;

  ${media.md`
    min-height: 526px;
  `}

  ${media.lg`
    min-height: 628px;
  `}
`;

const HeroIntro = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
  min-height: 1098px;

  ${media.md`
    min-height: 1123px
  `}

  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
    min-height: 904px;
  `}
`;

const TitleText = styled(Div)`
  display: flex;
  flex-direction: column;
  max-width: 781px;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin-left: 0px;
  }
`;

const InformationCardContainer = styled(Div)`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #f6f2ef;
  gap: 16px;
  justify-items: center;
`;

const DevelopmentSection = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 130px;
  padding-bottom: 130px;
  background-color: #f6f2ef;
  z-index: 2;
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  width: 180px;
  height: 50px;
  padding: 8px 15px;
  gap: 6px;
  ${media.lg`
   max-width: 100%;
   height: 47px;
  `}
`;

const GetInTouch = styled(StyledButton)`
  background-color: transparent;
  color: #251d97;
  &:hover {
    border: none;
    background-color: transparent;
  }
  &:before {
    background-color: #fff;
  }
  &:after {
    background-color: #fff;
  }
  &:not(:disabled):hover::after,
  &:not(:disabled):hover::before {
    background-color: #fff;
  }
`;

const ButtonContainer = styled(Div)`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 40px;
  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
  }
`;

const Steps = styled(Div)`
  background: url(${process.env.PUBLIC_URL + steps});
  width: 55px;
  height: 246px;
`;

const StepContainer = styled(Div)`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
`;

const StepTextContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  max-width: 250px;
`;

const ContentContainer = styled(Div)`
  max-width: 653px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin-bottom: 40px;
  }
`;

const Title = styled.p`
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  padding-bottom: 16px;
  color: #2d3232;
  ${media.md`
    font-size: 48.5px;
    line-height: 48.5px;
    letter-spacing: -1px;
  `};
  ${media.lg`
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -1px;
  `}
`;

const SandboxTitle = styled.p`
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  color: #2d3232;
  ${media.md`
    font-size: 48.5px;
    line-height: 48.5px;
    letter-spacing: -1px;
  `}
  ${media.lg`
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -1px;
  `}
`;

const StyledText = styled(Text)`
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 37.4px;
  padding-bottom: 50px;
  color: #2d3232;
  ${media.md`
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: 42.9px;
  `}
  ${media.lg`
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 49.5px;
  `}
`;

const BlurbText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
  font-family: "grotesk", px-grotesk, Arial, sans-serif;
  color: #2d3232;
  ${media.md`
    font-size: 21px;
    line-height: 25.2px;
  `}
  ${media.lg`
    font-size: 26px;
    line-height: 31.2px;
  `}
`;

const SandboxBlurbText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
  font-family: "grotesk", px-grotesk, Arial, sans-serif;
  color: #2d3232;

  ${media.md`
    font-size: 21px;
    line-height: 25.2px;
  `}
  ${media.lg`
    font-size: 26px;
    line-height: 31.2px;
  `}
`;

const HeroSection = styled.div`
  position: relative;
  background-color: #ede6df;
`;

const TextContainer = styled.div`
  width: 100%;
  color: #2d3232;

  ${media.sm`
    width: 70%;
  `}
  ${media.lg`
    width: 100%
  `}
`;

const SandboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledLink = styled.a`
  color: #251d97;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
`;

//#region
export const Home = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isSandboxCreated, setIsSandBoxCreated] = useState(false);
  const [sandboxResponse, setSandboxResponse] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientExists, setClientExists] = useState(false);
  const { isVisible, showMessageBubble } = useBubbleMessage();
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const auth = useAuth();
  const queryClient = useQueryClient();
  const token = auth.userData?.access_token ?? null;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [secretCode, setSecretCode] = useState("");
  const isMobileMode = getIsMobileMode();

  const toggleModal = () => {
    setIsModalVisible((isModalVisible) => !isModalVisible);
  };
  const updateModalState = (
    isModalVisible: boolean,
    shouldShowBubble: boolean,
    success: boolean
  ): void => {
    setIsModalVisible(isModalVisible);
    if (shouldShowBubble) {
      if (success) {
        showMessageBubble();
        setMessage("Copied to clipboard");
        setIsError(false);
        setIsWarning(false);
      } else if (!success) {
        showMessageBubble();
        setMessage("Copy failed");
        setIsError(true);
        setIsWarning(false);
      }
    }
  };

  useEffect(() => {
    checkSandboxStatus();
  }, []);

  const checkSandboxStatus = () => {
    SandboxServices.CheckSandboxStatus(auth.userData?.profile.sub, token)
      .then((resp) => {
        setSandboxResponse(true);
        setIsSandBoxCreated(resp.sandboxExists);
        setClientId(resp.clientId);
        setClientExists(resp.clientExists);
        setIsPageLoaded(true);
      })
      .catch((err) => {
        showMessageBubble();
        const response = ResponseErrorHandler.HandleResponseErrors(
          err,
          auth,
          queryClient
        );
        setSandboxResponse(true);
        setIsError(response.isError);
        setIsWarning(response.isWarning);
        setMessage(response.message);
      });
  };

  const createNewSecretKey = () => {
    //call api to create new secret key

    const regKey = SecretKey.regenerateSecretKey(token, clientId);
    regKey
      .then((res) => {
        setSecretCode(res.data.data.regenerateSecretKey);
        toggleModal();
      })
      .catch((err) => {
        showMessageBubble();
        const response = ResponseErrorHandler.HandleResponseErrors(
          err,
          auth,
          queryClient,
          "There was an error while regenerating secret key for Client: Client id unreachable, please try again"
        );
        setIsError(response.isError);
        setIsWarning(response.isWarning);
        setMessage(response.message);
      });
  };

  const copyApiUrl = () => {
    navigator.clipboard
      .writeText("https://sandbox.globalprocessing.com/sandbox/api/v1")
      .then(function () {
        showMessageBubble();
        setMessage("Copied to clipboard");
        setIsError(false);
        setIsWarning(false);
      })
      .catch(function () {
        showMessageBubble();
        setMessage("Copy failed");
        setIsError(true);
        setIsWarning(false);
      });
  };

  const scrollToContactForm = () => {
    const contactFormElement = document.getElementById("contactForm");
    if (contactFormElement) {
      contactFormElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const copyClientID = () => {
    navigator.clipboard
      .writeText(clientId)
      .then(function () {
        showMessageBubble();
        setMessage("Copied to clipboard");
        setIsError(false);
        setIsWarning(false);
      })
      .catch(function () {
        showMessageBubble();
        setMessage("Copy failed");
        setIsError(true);
        setIsWarning(false);
      });
  };
  //#endregion
  return (
    <>
      <BubbleMessage
        isVisible={isVisible}
        message={message}
        isWarning={isWarning}
        isError={isError}
      />
      <GlobalStyle />
      {isPageLoaded && (
        <>
          <DivMain>
            {isModalVisible && (
              <NewSecretKey
                isModalVisibleProp={isModalVisible}
                updateModalState={updateModalState}
                secretCode={secretCode}
              />
            )}
            <HeroSection>
              <Container>
                <MainHeader isSignedIn />
                {!isSandboxCreated && (
                  <>
                    <Intro>
                      <TitleText>
                        <TextContainer>
                          <Title>We're setting up your Sandbox</Title>
                          <BlurbText>
                            In the meantime, have a look at our documentation or
                            speak to a Thredd expert about your needs.
                          </BlurbText>
                        </TextContainer>
                        <ButtonContainer>
                          <StyledButton
                            label="API Documentation"
                            icon="arrowRight"
                            size={isMobileMode ? "small" : "medium"}
                            onClick={() => {
                              window.open(
                                "https://cardsapidocs.thredd.com/",
                                "_blank",
                                "noopener"
                              );
                            }}
                          />
                          <GetInTouch
                            label="Get in touch"
                            icon="arrowRight"
                            size={isMobileMode ? "small" : "medium"}
                            variant="secondary"
                            onClick={scrollToContactForm}
                          />
                        </ButtonContainer>
                      </TitleText>
                    </Intro>
                    <Img alt="sandbox" src={verticalGreen}></Img>
                    <PatternContainer />
                  </>
                )}
                {isSandboxCreated && (
                  <HeroIntro>
                    <ContentContainer>
                      <SandboxContainer>
                        <SandboxTitle>Sandbox</SandboxTitle>
                        <SandboxBlurbText>
                          You can now simulate transactions and try out our rich
                          functionality. Read the full guide{" "}
                          <StyledLink id='homeGuideLink' href="https://cardsapidocs.thredd.com/v0.0/reference/using-postman#install-the-gps-sandbox-postman-collection">
                            here
                          </StyledLink>{" "}
                          or just follow the steps below.
                        </SandboxBlurbText>
                      </SandboxContainer>
                      <StepContainer>
                        <Steps />
                        <StepTextContainer>
                          <BlurbText>
                            Download the Postman collection.
                          </BlurbText>
                          <BlurbText>
                            Add your Client ID and Secret Key.
                          </BlurbText>
                          <BlurbText>
                            Authenticate and start making calls.
                          </BlurbText>
                        </StepTextContainer>
                      </StepContainer>
                    </ContentContainer>
                    <SandboxAccessInformation
                      clientId={clientId}
                      clientExists={clientExists}
                      copyApiUrl={copyApiUrl}
                      copyClientID={copyClientID}
                      createNewSecretKey={createNewSecretKey}
                    />
                    <PatternContainer />
                  </HeroIntro>
                )}
              </Container>
            </HeroSection>
            <DevelopmentSection>
              <Container>
                <StyledText
                  variant={isMobileMode ? "heading2Bold" : "display2Bold"}
                >
                  Development guides
                </StyledText>
                <InformationCardContainer>
                  <InformationCard
                    icon="scale"
                    title="Postman"
                    informationText="Using the Thredd REST API collection in Postman."
                    url="https://cardsapidocs.thredd.com/v0.0/reference/using-postman"
                  />
                  <InformationCard
                    icon="platform"
                    title="API explorer"
                    informationText="Explore the API and download a sample Postman Collection."
                    url="https://cardsapidocs.thredd.com/v0.0"
                  />
                  <InformationCard
                    icon="document"
                    title="Documentation"
                    informationText="Learn about all the products and services that Thredd offer."
                    url="https://docs.thredd.com/"
                  />
                </InformationCardContainer>
              </Container>
            </DevelopmentSection>
          </DivMain>
          <ContactForm />
          <Footer footermode="light"></Footer>
        </>
      )}
    </>
  );
};
