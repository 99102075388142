import {DefaultTheme} from 'styled-components'
import {rgba} from 'utils'

const primaryBlue = {
  primaryBlue900: '#080357',
  primaryBlue500: '#0C0589',
  primaryBlue400: '#2E2989',
  primaryBlue300: '#484589',
  primaryBlue200: '#626089',
  primaryBlue100: '#F2F7FF',
}

const complimentaryBlue = {
  complimentaryBlue800: '#080357',
  complimentaryBlue500: '#285DF4',
  complimentaryBlue600: '#2E2989',
  complimentaryBlue300: '#484589',
  complimentaryBlue200: '#626089',
}

const darkBlue = {
  darkBlue900: '#1D2D4C',
  darkBlue800: '#273C66',
  darkBlue500: '#3A5B99',
  darkBlue200: '#B9C8E4',
}

const grey = {
  grey500: '#9098A7',
  grey300: '#A3A3A3',
  grey200: '#EAECF1',
  grey100: '#F8F9FC'
}

const red = {
  red900: '#CB040C',
  red500: '#CB2D33',
  orange900: '#EB450C',
  orange500: '#EB5F2F',
}

const green = {
  green900: '#49E717',
  green500: '#6EE748',
}

const gradients = {
  gradientMenu1: darkBlue.darkBlue900,
  gradientMenu2: '#080C14',
  gradientSkeleton1: '#B5C4C9', // goes from 50% opacity to 20%
  gradientCard1: '#3A416F',
  gradientCard2: '#141727',
}

const textColours = {
  textPrimary: '#2D3232',
  textSecondary: '#A3A3A3'
}

const backgrounds = {
  background: '#F6F2EF',
  secondaryBackground: '#B04CFF',
  darkBackground: '#DDD4C7',
}

const colors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  ...primaryBlue,
  ...darkBlue,
  ...grey,
  ...red,
  ...green,
  ...gradients,
  ...complimentaryBlue,
  ...textColours,
  ...backgrounds,
  linksDefault: '#0C0589',
  linksHover : '#63D0FB',

}

const shadows = {
  darkButton: `0px 8px 16px ${rgba(colors.darkBlue900, 0.2)}`,
  lightButton: `0px 8px 16px ${rgba(colors.primaryBlue500, 0.2)}`,
  bigBox: `0px 10px 30px ${rgba(colors.darkBlue900, 0.1)}`,
  smallBox: `0px 8px 20px ${rgba(colors.darkBlue900, 0.08)}`,
  darkButtonDrop: `0px 8px 8px ${rgba(colors.darkBlue900, 0.2)}`, // if we use drop-shadow, the blur parameter shold be halved due to a different specification https://stackoverflow.com/questions/39575894/why-does-box-shadow-look-different-than-filter-drop-shadow
}

const theme: DefaultTheme = {
  color: colors,
  shadow: shadows,
  space: [0, 1, 2, 3, 4, 5],
  inputHeight: 50,
  borderRadius: 4,
  verticalGap: 20,
  horizontalGap: 20,
}

export {colors, shadows, theme}
export type Color = typeof colors[keyof typeof colors]
