import axios from "axios";

async function CheckConfirmationCode(
  code: string | null,
  email: string | null
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}userManagement`,
      {
        query: `mutation($code : String!, $email : String!){ 
            checkConfirmationCode(
                confirmationCode: $code,
                email: $email       
          )
        }`,
        variables: {
          code: code,
          email: email,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      return "OK";
    })
    .catch((err: Error) => {
      throw {
        error: err,
      };
    });
}

async function ResendVerificationCode(email: string | null) {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}userManagement`,
      {
        query: `mutation($email: String!){
                  resendVerificationEmail(
                      emailAddress: $email
                  )
              }`,
        variables: {
          email: email,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      return "OK";
    })
    .catch((err: Error) => {
      throw {
        error: err,
      };
    });
}

const ConfirmationServices = {
  CheckConfirmationCode,
  ResendVerificationCode,
};

export default ConfirmationServices;
