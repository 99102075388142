import {colors} from 'config/theme'
import {css} from 'styled-components'

export const selectStyles = css`
  .Highlighter {
    line-height: 16px !important;
  }
  #selectContainer:hover > #radioSelect {
    fill: ${colors.primaryBlue400};
  }
  #selectContainer:active > #radioSelect {
    fill: ${colors.primaryBlue400};
  }
  #selectContainer:disabled > #radioSelect {
    fill: ${colors.primaryBlue400};
  }
  #selectContainer:hover > #radioUnselect {
    fill: ${colors.grey100};
  }
  #selectContainer:active > #radioUnselect {
    fill: ${colors.grey200};
  }
  #selectContainer:disabled > #radioUnselect {
    fill: ${colors.grey200};
  }
`
