import { Div } from "components";
import { ArrowDown, ArrowUp, Text } from "gps-design-system";
import React, { useState } from "react";
import styled from "styled-components";
import { StyledHR } from "./Footer";

const DockContainer = styled.div<{ collapsed: boolean }>`
  margin-top: 20px;
  width: 100%;
  height: ${(props) => (props.collapsed ? "50px" : "200px")};
  background-color: #fff;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled(Div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  color: #251d97;
  cursor: pointer;
`;

const LinkItem = styled.a`
  cursor: pointer;
  width: 130px;
  text-decoration: none;
  outline: 0;
  color: inherit;
  &:hover {
    text-decoration-line: underline;
  }
`;

const ContentContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 8px;
`;

interface LinkConfig {
  text: string;
  href: string;
}

interface CollapsibleDockProps {
  title: string;
  href: string;
  links: LinkConfig[];
}

const CollapsibleDock: React.FC<CollapsibleDockProps> = ({
  title,
  href,
  links,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleToggleDock = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <StyledHR />
      <DockContainer collapsed={collapsed} onClick={handleToggleDock}>
        <TitleContainer>
          <LinkItem href={href} style={{ marginBottom: "24px" }}>
            <Text variant="paragraphMediumBold" color="#251D97">
              {title}
            </Text>
          </LinkItem>
          {!collapsed ? <ArrowUp /> : <ArrowDown />}
        </TitleContainer>
        <ContentContainer>
          {!collapsed && (
            <>
              {links.map((link, index) => (
                <LinkItem
                  key={index}
                  href={link.href}
                  style={{ fontWeight: 400 }}
                >
                  {link.text}
                </LinkItem>
              ))}
            </>
          )}
        </ContentContainer>
      </DockContainer>
    </>
  );
};

export default CollapsibleDock;
