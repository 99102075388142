import { Div } from "components";
import React from "react";
import styled from "styled-components";
import { CloseIcon, LockIcon } from "assets/icons";
import { SquareModal } from "components/SquareModal";
import { Button, Text } from "gps-design-system";
import verticalBlue from "assets/Images/verticalBlue.png";
import horizontalBlue from "assets/Images/horizontalBlue.png";
import horizontalPurple from "assets/Images/horizontalPurple.svg";
import verticalPurple from "assets/Images/verticalPurple.svg";
import { getIsMobileMode } from "utils/mobile";
import Container from "styled/Container";
import { media } from "utils/media";
import useWindowResize from "hooks/useWindowResize";

interface Props {
  isModalVisibleProp: boolean;
  updateModalState: (arg: boolean, arg2: boolean, arg3: boolean) => void;
  secretCode: string;
}

const ModalTitleWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ModalKeyWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
`;
const KeyWrapperText = styled(Div)`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 10px;
  gap: 8px;
  border: 1px solid #d0d0d0;
  background: #fff;
`;
const ModalHeader = styled(Div)`
  display: flex;

  margin-top: 20px;
`;

const Title = styled(Text)`
  box-sizing: border-box;
  font-weight: 700;
  font-size: 42px;
  line-height: 32px;
  display: flex;
  justify-content: start;
  padding-left: 25px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  @media (min-width: 1420px) {
    font-size: 21px;
  }
  @media (min-width: 768px) {
    font-size: 21px;
  }
`;

const IconWrapper = styled(Div)`
  padding-left: 0px;
  font-size: 22px;
`;

const VerticalBlue = styled.img`
  height: 100%;
  padding-top: 50px;
  width: 100px;
`;

const HorizontalBlue = styled.img`
  height: 60px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.lg`
    flex-direction: row;
  `}
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  ${media.lg`
    max-width: 1024px;
    padding: 70px 10px 20px 70px;
  `}
`;

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const NewSecretKey: React.FC<Props> = ({
  isModalVisibleProp,
  updateModalState,
  secretCode,
}) => {
  const isModalVisible = isModalVisibleProp;
  const secretCodeCr = secretCode;
  const windowSize = useWindowResize().width;
  const isMobile = windowSize <= 1024;

  const closeModal = () => {
    updateModalState(false, false, false);
  };

  const copySandboxSecret = () => {
    navigator.clipboard
      .writeText(secretCodeCr)
      .then(function () {
        updateModalState(false, true, true);
      })
      .catch(function () {
        updateModalState(false, true, false);
      });
  };

  return (
    <SquareModal isModalVisible={isModalVisible} closeModal={closeModal}>
      <MainContainer>
        {!isMobile && <VerticalBlue alt="sandbox" src={verticalPurple} />}
        {isMobile && (
          <>
            {/* temporary fix until we get the image from design team */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <HorizontalBlue alt="sandbox" src={horizontalPurple} />
              <HorizontalBlue
                alt="sandbox"
                src={horizontalPurple}
                style={{
                  marginLeft: "-50px",
                }}
              />
            </div>
            <IconContainer>
              <CloseIcon
                onClick={closeModal}
                width={35}
                height={25}
                style={{ margin: "15px 30px" }}
              />
            </IconContainer>{" "}
          </>
        )}
        <StyledContainer>
          <ModalTitleWrapper>
            <ModalHeader>
              <IconWrapper>
                <LockIcon></LockIcon>
              </IconWrapper>
              <Title>Secret Key</Title>
            </ModalHeader>
            <div style={{ margin: "40px 0px 0px 0px" }}>
              <Description style={{ maxWidth: "750px" }}>
                It will not be possible to view this secret after you close this
                panel, so please take a copy of it now. If you lose the secret,
                you will need to generate a new one.
              </Description>
            </div>
          </ModalTitleWrapper>

          <ModalKeyWrapper>
            <label
              style={{
                color: "#A3A3A3",
                lineHeight: "1.2",
                margin: "35px 0px 5px 0px",
                fontWeight: "400",
                fontSize: "18px",
                justifyContent: "space-between",
              }}
            >
              {" "}
              Sandbox Secret Key{" "}
            </label>
            <KeyWrapperText>
              <span
                style={{
                  fontWeight: "300",
                  color: "#2D3232",
                  fontSize: "18px",
                  lineHeight: "1.2",
                  wordBreak: "break-all",
                }}
                id='secretKeyText'
              >
                {secretCodeCr}
              </span>
            </KeyWrapperText>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "55px",
                margin: "00px 0px 0px 0px",
                justifyContent: "end",
              }}
            >
              <StyledButton
                size="medium"
                label="Copy"
                onClick={copySandboxSecret}
                data-testid='secretKeyButton'
              />
            </div>
          </ModalKeyWrapper>
        </StyledContainer>
        {!isMobile && (
          <div
            style={{
              cursor: "pointer",
              margin: "0px 0px 0px 0px",
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CloseIcon
              onClick={closeModal}
              width={35}
              height={25}
              style={{ margin: "15px 15px" }}
            />
          </div>
        )}
      </MainContainer>
    </SquareModal>
  );
};

export default NewSecretKey;
