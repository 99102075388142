import axios from "axios";

async function SubmitForgottenPassword(email: string) {
  return await axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}userManagement`,
      {
        query: `mutation($email: String!){
                resetPassword(
                  emailAddress: $email
              )
            }`,
        variables: {
          email: email,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      return "OK";
    })
    .catch((err: Error) => {
      throw {
        error: err,
      };
    });
}

const ForgottenPasswordService = {
  SubmitForgottenPassword,
};

export default ForgottenPasswordService;
