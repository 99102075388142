import styled from "styled-components";
import { Column, Row } from "./Wrappers";
import useWindowResize from "hooks/useWindowResize";

const Rectangle = styled.div<{ isLight?: boolean; isMid?: boolean }>`
  width: 30px;
  height: 22.59px;
  background-color: ${({ isLight, isMid }) => {
    if (isLight) {
      return "#F6F2EF";
    } else if (isMid) {
      return "#EDE6DF";
    } else {
      return "transparent";
    }
  }};
`;

const StyledColumn = styled(Column)`
  width: 120px;
`;

const Square = () => (
  <StyledColumn>
    <Row>
      <Rectangle isMid />
      <Rectangle isMid />
      <Rectangle isMid />
      <Rectangle isLight />
    </Row>
    <Row>
      <Rectangle isMid />
      <Rectangle isLight />
      <Rectangle isLight />
      <Rectangle isMid />
    </Row>
    <Row>
      <Rectangle isMid />
      <Rectangle isLight />
      <Rectangle isMid />
      <Rectangle isLight />
    </Row>
  </StyledColumn>
);

const PatternBox = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -23px;
  z-index: 3;
`;

export const PatternContainer = () => {
  const windowSize = useWindowResize();
  return (
    <PatternBox>
      {windowSize.width < 1024 ? (
        <>
          <Square />
          <Square />
        </>
      ) : (
        <>
          <Square />
          <Square />
          <Square />
          <Square />
        </>
      )}
    </PatternBox>
  );
};
