import React from "react";
import "./index.css";
import { App } from "./App";
import { FlagsProvider } from 'flagged';
import { flags } from "config/flags";
import ReactDOM from 'react-dom'

ReactDOM.render(
  <React.StrictMode>
    <FlagsProvider features={flags}>
      <App />
    </FlagsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);