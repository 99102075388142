import { DropdownIcon } from "assets/icons";
import { Text } from "components";
import { colors } from "config/theme";
import styled from "styled-components";

const StyledButton = styled.button<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background-color: ${({ isExpanded }) =>
    isExpanded ? colors.darkBlue500 : colors.darkBlue900};
  ${({ isExpanded }) => (isExpanded ? "" : ``)}
  justify-content: space-between;
  user-select: none;

  ${({ isExpanded }) =>
    isExpanded
      ? ""
      : `&:hover:not(:disabled) {background-color: ${colors.darkBlue800};}`}

  &:disabled {
    background-color: ${colors.grey300};
    filter: none;
    cursor: default;
  }
`;

interface DropDownButtonProps {
  id: string;
  text: string;
  isExpanded: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const DropDownButton = ({
  id,
  text,
  isExpanded,
  onClick,
  disabled,
}: DropDownButtonProps) => {
  const iconStyle = isExpanded ? { transform: "rotate(180deg)" } : {};

  return (
    <StyledButton
      id={id}
      onClick={onClick}
      disabled={disabled}
      isExpanded={isExpanded}
    >
      <Text fontType="buttonLink">{text}</Text>
      <DropdownIcon style={iconStyle} stroke={colors.white} />
    </StyledButton>
  );
};

interface MiniDropDownButtonProps {
  id: string;
  isExpanded: boolean;
  onClick: () => void;
}

export const MiniDropDownButton = ({
  id,
  isExpanded,
  onClick,
}: MiniDropDownButtonProps) => {
  const iconStyle = isExpanded ? { transform: "rotate(180deg)" } : {};

  return (
    <DropdownIcon
      id={id}
      style={iconStyle}
      stroke={colors.darkBlue900}
      onClick={onClick}
      cursor="pointer"
    />
  );
};
