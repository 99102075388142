import { MOBILE_WIDTH } from "config/constants";
import {
  ArrowRight,
  Document,
  Elipsis,
  InfoCircle,
  Platform,
  Scale,
  Text,
} from "gps-design-system";
import { useState } from "react";
import styled from "styled-components";
import { media } from "utils/media";
import { Div } from "./Wrappers";

const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: 40px;
  cursor: pointer;
  ${media.md`
    width: 40%;
  `}
  ${media.lg`
    width: 45%;
  `}
  ${media.xl`
    width: 32.45%;
  `}
`;

const Container = styled(Div)`
  height: 38px;
  width: 38px;
  background: #251d97;
  display: flex;
  flex-direction: row;
  color: #fff;
  align-items: center;
  justify-content: center;
  transition: width 0.4s ease, background 0.4s ease;
  ${Wrapper}:hover & {
    width: 60px;
  }
`;

const Icon = styled(Div)`
  font-size: 2.5rem;
`;

const StyledParagraph = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
  padding-bottom: 12px;
  color: #2d3232;
  ${media.md`
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.2px;
  `}
  ${media.lg`
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.6px;
  `}
`;

const TextContainer = styled(Div)`
  margin-top: 125px;
  margin-bottom: 40px;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin-top: 40px;
  }
`;

const StyleContentText = styled(Text)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  color: #2d3232;
  ${media.md`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height:20.4px;
    letter-spacing: -0.5px;
  `}
  ${media.lg`
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.5px;
  `}
`;

type InformationCardProps = {
  icon: string;
  title: string;
  informationText: string;
  url: string;
};

export const InformationCard = ({
  icon,
  title,
  informationText,
  url,
}: InformationCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const navigateTo = () => {
    window.open(url, "_blank", "noopener");
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "document":
        return <Document />;
      case "platform":
        return <Platform />;
      case "scale":
        return <Scale />;
      default:
        return <InfoCircle />;
    }
  };

  return (
    <Wrapper
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onClick={navigateTo}
      id={`devGuide${title.replace(/\s/g, "")}`}
    >
      <Icon>{getIcon(icon)}</Icon>
      <TextContainer>
        <StyledParagraph variant="heading4Bold">{title}</StyledParagraph>
        <StyleContentText variant="paragraphLarge">
          {informationText}
        </StyleContentText>
      </TextContainer>
      <Container>
        {isHovered && <Elipsis />}
        <ArrowRight />
      </Container>
    </Wrapper>
  );
};
