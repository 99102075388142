import React, { ReactElement } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Div, Row, Text } from "components";
import { useMainPath } from "config/hooks";
import { colors } from "config/theme";
import _ from "lodash";
import styled, { css } from "styled-components";
import Background from "assets/Images/sidebarActive.png";

export interface Props {
  name: string;
  link: string;
  icon: ReactElement;
  external?: boolean;
}

const LeftItemRow = styled(Row)<{ isActive: boolean }>`
  margin-top: 20px;
  height: 44px;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.isActive
      ? css`
          color: ${colors.white};
          padding-left: 10px;
        `
      : css`
          color: ${colors.textPrimary};
          padding-left: 20px;
        `}
`;

const ActiveItemBox = styled(Div)<{ isActive: boolean }>`
  width: 100%;
  height: 44px;
  left: 0px;
  top: 0px;
  background-image: url(${process.env.PUBLIC_URL + Background});
  margin-right: 20px;
  ${(props) =>
    props.isActive
      ? css`
          display: flex;
        `
      : css``}
`;

const IconContainer = styled(Div)<{ isActive: boolean }>`
  margin-right: 16px;
  display: flex;
  align-items: center;
  ${(props) =>
    props.isActive
      ? css`
          padding-left: 10px;
        `
      : css``}
`;

export const LeftMenuItem = ({ name, link, icon, external }: Props) => {
  const navigate = useNavigate();
  const { mainPath } = useMainPath();
  const [searchParams] = useSearchParams();

  const id = `${_.camelCase(name)}LeftMenu`;
  const isActive = mainPath === link;
  const textColor = isActive ? colors.white : colors.textPrimary;
  const textType = "paragraphMediumSemibold";
  const iconComponent = React.cloneElement(icon, {
    width: 20,
    height: 20,
    currentcolor: isActive ? colors.primaryBlue500 : colors.grey300,
  });

  const navigateToLink = () => {
    if (external) {
      window.open(link, "_blank", "noopener");
    } else {
      const shouldReload = link === mainPath && searchParams.toString() !== "";
      navigate(link);
      shouldReload && window.location.reload();
    }
  };

  return (
    <LeftItemRow onClick={navigateToLink} isActive={isActive}>
      {isActive && (
        <>
          <ActiveItemBox isActive={isActive} id={id + "Container"}>
            <IconContainer isActive={isActive}>{iconComponent}</IconContainer>
            <Text
              id={id}
              color={textColor}
              fontType={textType}
              style={{ alignSelf: "center" }}
            >
              {name}
            </Text>
          </ActiveItemBox>
        </>
      )}
      {!isActive && (
        <>
          <IconContainer isActive={isActive}>{iconComponent}</IconContainer>
          <Text id={id} color={textColor} fontType={textType}>
            {name}
          </Text>
        </>
      )}
    </LeftItemRow>
  );
};
