import ResponseErrorHandler from "Services/ResponseErrorHandler";
import SendMail from "Services/SendMail";
import horizontalPink from "assets/Images/horizontalPink.png";
import verticalPink from "assets/Images/verticalPink.png";
import { SquareDots } from "assets/icons";
import { BubbleMessage, Column, Div, Row, useBubbleMessage } from "components";
import { CONTACT_US_FORM, MOBILE_WIDTH } from "config/constants";
import { Button, Text, TextInput, Textarea } from "gps-design-system";
import useWindowResize from "hooks/useWindowResize";
import { useAuth } from "oidc-react";
import { MutableRefObject, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import Container from "styled/Container";
import { media } from "utils/media";

interface IMailInput {
  topic: string;
  message: string;
}
enum FormFieldsEnum {
  topic = "topic",
  message = "message",
}

const FormContainer = styled(Div)`
  gap: 10%;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
  }
`;

const FildsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 32px;
  background-color: #fff;
  z-index: 10;
  width: 100%;
  @media (max-width: ${MOBILE_WIDTH}) {
    width: 100%;
    padding: 30px;
  }
`;

const ContactAction = styled(Div)`
  justify-content: flex-end;
  display: flex;
  margin: 15px 0px 15px 0px;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin: 23px 0px;
  }
`;

const VerticalPink = styled(Div)`
  position: absolute;
  background: url(${process.env.PUBLIC_URL + verticalPink});
  z-index: 0;
  left: 0;
  height: 840px;
  width: 570px;

  ${media.md`
    left: -40%;
  `}
  ${media.lg`
    left: -30%
  `}
  ${media.xl`
    left: 0;
  `}
`;

const HorizontalPink = styled(Div)`
  background: url(${process.env.PUBLIC_URL + horizontalPink});
  height: 72px;
  width: 100%;
`;

const ContactContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 90px;
  z-index: 10;
  ${media.lg`
    display: flex;
    flex-direction: row;
    padding-top: 90px;
  `}
`;

const TextContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  background-color: #251d97;
  padding: 60px;
  z-index: 10;
  @media (max-width: ${MOBILE_WIDTH}) {
    padding: 40px;
  }
  ${media.lg`
    min-width: 540px;
  `}
  ${media.xl`
    min-width: 605px;
  `}
`;

const SquareDotsIcon = styled(SquareDots)`
  align-self: center;
  min-width: 25px;
`;

const TextRow = styled(Row)`
  margin: 15px 0px;
`;

const LabelInput = styled(Column)<{ width?: string }>`
  width: ${({ width }) => width ?? "100%"};
  gap: 4px;
  color: #2d3232;
`;
const RequiredSymbol = styled(Text)`
  color: rgb(255, 36, 158);
`;

const LegalText = styled(Div)`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const LabelText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
`;

const StyledTextInput = styled(TextInput)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0px;
  padding: 12px;
  font-family: "grotesk", px-grotesk, Arial, sans-serif;
  ::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: -0.5px;
  }
`;

const StyledButton = styled(Button)`
  font-weight: bold;
  font-size: 14px;
  padding: 15px;
  height: 45px;
  min-width: 180px;
  line-height: normal;
`;

const RequiredLabel = ({ label }: { label: string }) => (
  <Row>
    <LabelText>{label}</LabelText>
    <RequiredSymbol>*</RequiredSymbol>
  </Row>
);

const Title = styled(Text)`
  font-size: 59px;
  @media (min-width: 1700px) {
    font-size: 59px;
  }
  @media (min-width: 1230px) {
    font-size: 45px;
  }
  @media (min-width: 768px) {
    font-size: 39px;
  }
`;

const ContactTitle = styled(Text)`
  font-size: 34px;
  @media (min-width: 640px) {
    font-size: 39px;
  }
  @media (min-width: 820px) {
    font-size: 45px;
  }
`;

const BulletText = styled(Text)`
  line-height: 1.2;
  font-size: 20px;
`;

const StyledTextArea = styled(Textarea)`
  width: 100%;
  min-width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 1008px;
  ${media.md`
    height: 954px;
  `};
  ${media.lg`
    flex-direction: row;
    height: 663px;
  `}
`;

//#region
export const ContactForm = () => {
  const { handleSubmit } = useForm<IMailInput>({ mode: "onChange" });
  const { isVisible, showMessageBubble } = useBubbleMessage();
  const [message, setMessage] = useState("");
  const [bubbleMessage, setBubbleMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const topicInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const messageInputRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const [topicInputFormErrors, setTopicInputFormErrors] = useState<
    any | null
  >();
  const [messageInpuFormErrors, setMessageInputFormErrors] = useState<
    any | null
  >();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const auth = useAuth();
  const queryClient = useQueryClient();
  const token = auth.userData?.access_token ?? null;
  const [topic, setTopic] = useState("");
  const onSubmit: SubmitHandler<IMailInput> = async (data) => {
    data.topic = topic;
    data.message = message;
    const validateTopic = validateField(data.topic, FormFieldsEnum.topic);
    const validateMessage = validateField(data.message, FormFieldsEnum.message);

    if (validateTopic) {
      setTopicInputFormErrors(null);
    } else if (validateMessage) {
      setMessageInputFormErrors(null);
    }

    if (validateTopic && validateMessage) {
      setIsButtonDisabled(true);
      setTopicInputFormErrors(null);
      setMessageInputFormErrors(null);
      const sendData = SendMail.SendContactFormMessage(data, token);
      sendData
        .then(() => {
          setIsButtonDisabled(true);
          setTopic("");
          setMessage("");
          showMessageBubble();
          setBubbleMessage("Your message has been sent");
          setIsError(false);
          setIsWarning(false);
        })
        .catch((err) => {
          setIsButtonDisabled(false);
          showMessageBubble();
          const response = ResponseErrorHandler.HandleResponseErrors(
            err,
            auth,
            queryClient,
            "Your message could not be sent"
          );
          setIsError(response.isError);
          setIsWarning(response.isWarning);
          setBubbleMessage(response.message);
        });
    }
  };
  //input validation for message
  const validateField = (value: string, field: FormFieldsEnum) => {
    if (topicInputFormErrors == null && messageInpuFormErrors == null) {
      if (
        (value === undefined || value.trim().length === 0) &&
        field === FormFieldsEnum.message
      ) {
        setMessageInputFormErrors("Message is required");
        return false;
      }
      if (
        (value === undefined || value.trim().length === 0) &&
        field === FormFieldsEnum.topic
      ) {
        setTopicInputFormErrors("Topic is required");
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  const checkForInvalidCharacters = (
    stringToCheck: string,
    textArea: string
  ) => {
    const errorMsg = "Input contains invalid character.";
    if (stringToCheck.indexOf('"') > -1 || stringToCheck.indexOf("\\") > -1) {
      setIsButtonDisabled(true);
      setErrorHandlers(textArea, errorMsg);
      return true;
    } else if (
      topicInputRef.current.value.trim().length +
        messageInputRef.current.value.trim().length >
      2500
    ) {
      setErrorHandlers(
        "topicInput",
        "The topic and message together must not exceed 2500 characters"
      );
      setErrorHandlers(
        "messageInput",
        "The topic and message together must not exceed 2500 characters"
      );
      return true;
    } else {
      setErrorHandlers(textArea, null);
    }
  };

  const checkFormValues = () => {
    let formValues = false;
    let invalidCharacters = false;
    let lengthValidation = false;

    if (
      topicInputRef.current.value.trim() &&
      messageInputRef.current.value.trim()
    ) {
      formValues = true;
    } else {
      formValues = false;
    }

    let topicInput = !checkForInvalidCharacters(
      topicInputRef.current.value.trim(),
      "topicInput"
    );

    let messageInput = !checkForInvalidCharacters(
      messageInputRef.current.value.trim(),
      "messageInput"
    );

    if (topicInput && messageInput) {
      invalidCharacters = false;
    } else {
      invalidCharacters = true;
    }

    if (formValues && !invalidCharacters) {
      return true;
    } else {
      return false;
    }
  };

  const setErrorHandlers = (formId: string, errorMsg: string | null) => {
    switch (formId) {
      case "topicInput":
        setTopicInputFormErrors(errorMsg);
        break;
      case "messageInput":
        setMessageInputFormErrors(errorMsg);
        break;
    }
  };

  const windowSize = useWindowResize();
  const { width } = windowSize;
  const isMobile = width <= 640;
  //#endregion
  return (
    <FormContainer id={CONTACT_US_FORM}>
      <BubbleMessage
        isVisible={isVisible}
        message={bubbleMessage}
        isWarning={isWarning}
        isError={isError}
      />
      <div style={{ position: "relative", zIndex: "1" }}>
        {width >= 1024 ? <VerticalPink /> : <HorizontalPink />}
        <Container padding={width <= 1024 ? "0" : "16px"}>
          <ContactContainer>
            <CardContainer>
              <TextContainer>
                <div
                  style={
                    isMobile
                      ? { maxWidth: "450px", marginTop: "50px" }
                      : { maxWidth: "450px" }
                  }
                >
                  <ContactTitle
                    variant={isMobile ? "heading1Bold" : "display1Bold"}
                    color="#FFF"
                  >
                    Contact our experts.
                  </ContactTitle>
                  <div style={{ margin: "50px 20px", gap: "15px" }}>
                    <BulletText variant="paragraphLarge" color="#FFF">
                      Get in touch to:
                    </BulletText>
                    <TextRow>
                      <SquareDotsIcon />
                      <BulletText variant="paragraphLarge" color="#FFF">
                        Ask any technical questions
                      </BulletText>
                    </TextRow>
                    <TextRow>
                      <SquareDotsIcon />
                      <BulletText variant="paragraphLarge" color="#FFF">
                        Speak about your custom requirements
                      </BulletText>
                    </TextRow>
                    <TextRow>
                      <SquareDotsIcon />
                      <BulletText variant="paragraphLarge" color="#FFF">
                        Talk with a payments expert
                      </BulletText>
                    </TextRow>
                  </div>
                </div>
              </TextContainer>
              <FildsContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextRow>
                    <LabelInput width="100%">
                      <RequiredLabel label="Subject" />
                      <StyledTextInput
                        aria-label="subject"
                        value={topic}
                        onChange={setTopic}
                        isValid={topicInputFormErrors}
                        placeholder="Subject..."
                        maxLength={128}
                        width="100%"
                      />
                    </LabelInput>
                  </TextRow>
                  <TextRow>
                    <LabelInput width="100%">
                      <RequiredLabel label="How can we help?" />
                      <StyledTextArea
                        aria-label="message"
                        maxLength={2300}
                        value={message}
                        onChange={setMessage}
                        minHeight={width < 1024 ? "250px" : "310px"}
                        minWidth="100%"
                        resize="none"
                        placeholder="Write your message..."
                      />
                    </LabelInput>
                  </TextRow>
                  <div
                    style={{
                      maxWidth: "550px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <LegalText>
                      By submitting this form I give my consent to Thredd using
                      my personal information to send me communications
                      regarding Thredd's products, events, reports and services.{" "}
                      <a href="https://www.thredd.com/privacy-policy/">
                        Privacy Policy.
                      </a>
                    </LegalText>
                  </div>
                  <ContactAction>
                    <StyledButton
                      variant="primary"
                      label="Send Enquiry"
                      icon="arrowRight"
                      type="submit"
                      size={isMobile ? "slimFullWidth" : "medium"}
                    />
                  </ContactAction>
                </form>
              </FildsContainer>
            </CardContainer>
          </ContactContainer>
        </Container>
      </div>
    </FormContainer>
  );
};
