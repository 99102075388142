import { Navigate } from "react-router-dom";

import { Column, Div, PrimaryButton, Text } from "components";
import { useAuth } from "oidc-react";
import styled from "styled-components";

const LOGO_HEIGHT = 60;

const Logo = styled.img`
  height: ${LOGO_HEIGHT}px;
  width: auto;
  margin-left: -${LOGO_HEIGHT * 0.8}px;
`;

const Wrapper = styled(Column)`
  box-sizing: border-box;
`;

export const Login = () => {
  const auth = useAuth();

  if (auth.userData && !auth.isLoading) {
    const latestLocation = window.sessionStorage.getItem("latestLocation");
    window.sessionStorage.removeItem("latestLocation");
    return <Navigate to={"/"} />;
  }

  const login = () =>
    auth.signIn().catch((err) => console.error("Failed authorization: ", err));

  return (
    <Wrapper
      minHeight="100vh"
      alignItems="center"
      justifyContent="space-between"
      p={20}
    >
      <Logo alt="logo" src="/Thredd_Logo_Indigo_RGB.svg" />
      <Column alignItems="center" gap width={400} p={25}>
        <Column alignItems="center">
          <Text>Please sign in to continue to your Thredd account</Text>
        </Column>
        <PrimaryButton onClick={login} label="Sign in" />
      </Column>
      <Div height={LOGO_HEIGHT} />
    </Wrapper>
  );
};
