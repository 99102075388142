import {colors} from 'config/theme'
import {css} from 'styled-components'

export const virtualizedTableStyles = css`
  .ReactVirtualized__Table__row {
    cursor: pointer;
    outline: none;
    z-index: 1;
  }
  #TableRowWrapper:hover {
    background-color: ${colors.grey100} !important;
    margin-left: 0px !important;
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
`
