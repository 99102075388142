import { useEffect, useRef } from "react";
import createRoot from "react-dom";

import { animated, useSpring } from "@react-spring/web";

import { Div, Text } from "components";
import { colors } from "config/theme";
import styled from "styled-components";

interface Props {
  isVisible: boolean;
  message?: string;
  isError?: boolean;
  isWarning?: boolean;
}

const VISIBLE_OFFSET = 54;
const HIDDEN_OFFSET = -80;

const Container = styled(animated.div)<{ color: string }>`
  will-change: transform;
  z-index: 11;
  position: fixed;
  top: ${HIDDEN_OFFSET}px;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  right: 50%;
  left: 40%;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
  background: ${({ color }) => color};
  @media only screen and (max-width: 640px) {
    left: 20px;
    right: 20px;
    width: -webkit-fill-available;
  }
`;

const TextWrapper = styled(Div)`
  display: flex;
`;

export const BubbleMessage = ({
  isVisible,
  message,
  isError,
  isWarning,
}: Props) => {
  const previousIsVisible = useRef(isVisible);
  const root = document.getElementById("root") as Element;
  let mainColor;
  if (isError) {
    mainColor = colors.red900;
  } else if (isWarning) {
    mainColor = colors.orange900;
  } else {
    mainColor = colors.green900;
  }

  const [style, animate] = useSpring(() => ({
    top: HIDDEN_OFFSET,
    config: { tension: 200, position: isVisible ? "sticky" : "absolute" },
  }));

  const animateShow = async () => {
    animate.start({ top: VISIBLE_OFFSET });
    previousIsVisible.current = true;
  };

  const animateHide = async () => {
    animate.start({ top: HIDDEN_OFFSET });
    previousIsVisible.current = false;
  };

  useEffect(() => {
    if (isVisible && !previousIsVisible.current) animateShow();
    if (!isVisible && previousIsVisible.current) animateHide();
  }, [isVisible]);

  const bubbleMessage = (
    <Container color={mainColor} style={style} id="tstrMsg">
      <TextWrapper>
        {!!message && (
          <Text fontType="paragraph02" color={colors.white}>
            {message}
          </Text>
        )}
      </TextWrapper>
    </Container>
  );

  return createRoot.createPortal(bubbleMessage, root);
};
