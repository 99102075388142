import { useNavigate } from "react-router-dom";

import {
  WhiteLayoutHomeIcon,
  WhiteCrossIcon,
  AtomIcon,
  DogEarIcon,
} from "assets/icons";
import { Column, Div, Text } from "components";
import { colors } from "config/theme";
import styled from "styled-components";

import { LeftMenuItem } from "./components/LeftMenuItem";
import { LEFT_MENU_WIDTH, MOBILE_WIDTH } from "config/constants";
import { getIsMobileMode } from "utils/mobile";

export const LEFT_MENU_TOP_LINKS = [
  { name: "Home", link: "/", icon: <WhiteLayoutHomeIcon /> },
  {
    name: "API Explorer (Sandbox)",
    link: "https://cardsapidocs.thredd.com/v0.0",
    icon: <AtomIcon />,
    external: true,
  },
];

export const LEFT_MENU_BOTTOM_LINKS = [
  {
    name: "Existing Thredd Guides",
    link: "https://docs.thredd.com/",
    icon: <DogEarIcon />,
    external: true,
  },
  {
    name: "API Explorer (Full)",
    link: "https://cardsapidocs.thredd.com/",
    icon: <AtomIcon />,
    external: true,
  },
];

const Container = styled(Column)`
  width: ${LEFT_MENU_WIDTH};
  flex-shrink: 0;
  background: ${colors.white}
  transition-delay: 0.1s;
  transition: width 0.8s;

  @media (max-width: ${MOBILE_WIDTH}) {
    width: 0px;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
`;

const LogoWrapper = styled(Div)`
  margin-top: 33px;
  margin-bottom: 26px;
  margin-left: 18px;
  display: flex;
  cursor: pointer;
`;

const BottomLinks = styled(Div)`
  margin-top: auto;
  padding-bottom: 30px;
  text-decoration: none;
`;

const TopLinks = styled(Div)``;

const Logo = styled.img`
  width: 122px;
  height: auto;
`;

const ProfileContainer = styled(Div)`
  width: 40px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  z-index: 1;
  position: absolute;
  opacity: 0%;
`;

export const LeftMenu = () => {
  const navigate = useNavigate();
  const isMobileMode = getIsMobileMode();
  const topLinkItems = LEFT_MENU_TOP_LINKS.map((it) => (
    <LeftMenuItem key={it.name} {...it} />
  ));

  const toggleSidebar = () => {
    let sidebar = document.getElementById("sidebar");
    let content = document.getElementById("mainContent");
    let sidebarButton = document.getElementById("sidebarButton");
    let topNavHamburger = document.getElementById("topNavHamburger");
    if (sidebar && content && sidebarButton && topNavHamburger) {
      sidebar.style.width = "0px";
      content.style.filter = "";
      sidebarButton.style.opacity = "0%";
      sidebarButton.style.zIndex = "-1";
    }
  };

  const bottomLinkItems = LEFT_MENU_BOTTOM_LINKS.map((it) => (
    <LeftMenuItem key={it.name} {...it} />
  ));

  return (
    <>
      <Container id="sidebar">
        <LogoWrapper id="leftMenuLogo" onClick={() => navigate("/")}>
          <Logo alt="logo" src="/Thredd_Logo_Indigo_RGB.svg" />
        </LogoWrapper>
        <TopLinks>{topLinkItems}</TopLinks>
        <BottomLinks>{bottomLinkItems}</BottomLinks>
      </Container>
      {isMobileMode && (
        <ProfileContainer
          id="sidebarButton"
          onClick={toggleSidebar}
          style={{ background: `${colors.primaryBlue500}`, cursor: "pointer" }}
        >
          <Text fontType="body1Bold" color={colors.darkBlue900}>
            <WhiteCrossIcon style={{ paddingTop: "5px" }} />
          </Text>
        </ProfileContainer>
      )}
    </>
  );
};
