import { useNavigate } from "react-router-dom";

import { BreadcrumbsRightIcon } from "assets/icons";
import { Div, Row, Text } from "components";
import { routes } from "config/navigation/mainNavigator/routes";
import { colors } from "config/theme";
import styled from "styled-components";
import useBreadcrumbs, { BreadcrumbData } from "use-react-router-breadcrumbs";
import { getIsMobileMode } from "utils/mobile";

interface ItemProps {
  data: BreadcrumbData;
  index: number;
  numberOfItems: number;
}

const EXCLUDE_PATHS = ["/"];

const RightArrow = styled(BreadcrumbsRightIcon)`
  margin-left: 8px;
  margin-right: 8px;
  width: 12px;
  height: 24px;
`;

const CenteredRow = styled(Row)`
  align-items: center;
`;

const BreadcrumbsContainer = styled(CenteredRow)`
  height: 24px;
  cursor: pointer;
`;

const LogoWrapper = styled(Div)`
  margin-top: 24px;
  margin-bottom: 30px;
  justify-content: center;
  display: flex;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 140px;
  height: auto;
  @media only screen and (max-width: 640px) {
    width: 121px;
    height: auto;
  }
`;

export const BreadcrumbsItem = ({ data, index, numberOfItems }: ItemProps) => {
  const navigate = useNavigate();
  const isFirstItem = index === 0;
  const isLastItem = index === numberOfItems - 1;
  const textColor = isLastItem ? colors.darkBlue900 : colors.darkBlue200;
  const textType = isLastItem ? "heading4" : "body1Bold";
  const navigateToPath = () => navigate(data.match.pathname);

  return (
    <CenteredRow onClick={navigateToPath}>
      {!isFirstItem && <RightArrow />}
      <Text fontType={textType} color={textColor}>
        {data.breadcrumb}
      </Text>
    </CenteredRow>
  );
};

export const Breadcrumbs = () => {
  const navigate = useNavigate();
  const isMobileMode = getIsMobileMode();
  let breadcrumbs = null;

  if (isMobileMode) {
    breadcrumbs = (
      <LogoWrapper id="leftMenuLogo" onClick={() => navigate("/")}>
        <Logo alt="logo" src="/Thredd_Logo_Indigo_RGB.svg" />
      </LogoWrapper>
    );
  } else {
    breadcrumbs = (
      <Text fontType="heading4" color={colors.primaryBlue500}>
        Developer Portal
      </Text>
    );
  }

  return <BreadcrumbsContainer>{breadcrumbs}</BreadcrumbsContainer>;
};
