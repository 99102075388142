import styled from 'styled-components'
import {MenuItem, DropdownMenu, Text} from 'gps-design-system'
import {useRef, useState} from 'react'
import {useAuth} from 'oidc-react'
import {decodeUser} from 'utils'
import { Div } from 'components'

interface Props {
  onSignOut: () => void
}

const CustomItemContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  margin: 8px 16px;
  gap: 8px;
`

const StyledDropdownMenu = styled(DropdownMenu)`
  min-width: 150px;
`

const BottomRightRectangle = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
`

const StyledButton = styled.button`
  position: relative;
  appearance: none;
  border: none;

  color: #FFFFFF;
  width: 40px;
  height: 40px;
  cursor: pointer;

  background-color: #B04CFF;

  &:hover,
  &:active {
    background-color: #251D97;
  }
`

export const ProfileStatus = ({ onSignOut }: Props) => {
  const ref = useRef(null)
  const auth = useAuth();
  const {name, organizationName, email} = decodeUser(auth.userData)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const token = auth.userData?.access_token ?? null;
  const initials = name
    .split(' ')
    .map(word => word[0].toUpperCase())
    .join('')

  const closeDropdown = () => setIsDropdownVisible(false)
  const showDropdown = () => setIsDropdownVisible(true)

  return (
    <div ref={ref}>
      <StyledButton onClick={showDropdown} data-testid="profileDropdown">
        {initials}
        <BottomRightRectangle />
      </StyledButton>
      {isDropdownVisible && (
        <StyledDropdownMenu targetRef={ref} onClose={closeDropdown}>
          <CustomItemContainer>
            <Text variant="paragraphSmall">{name}</Text>
            <Text variant="paragraphSmall">{email}</Text>
            <Text variant="paragraphSmall">{organizationName}</Text>
          </CustomItemContainer>
          <MenuItem icon="share" label="Log out" onClick={onSignOut} />
        </StyledDropdownMenu>
      )}
    </div>
  );
};
