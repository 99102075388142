import { useEffect, useRef, useState } from "react";

import { animated, useSpring } from "@react-spring/web";

import { UpCircleIcon } from "assets/icons";
import { HEADER_HEIGHT } from "config/constants";
import { colors, shadows } from "config/theme";
import _ from "lodash";
import styled from "styled-components";

const Container = styled(animated.div)`
  cursor: pointer;
  will-change: transform;
  position: fixed;
  right: -4px;
  bottom: 48px;
  width: 52px;
  height: 40px;
  background: ${colors.background};
  box-shadow: ${shadows.darkButton};
  border-radius: 30px 0px 0px 30px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  box-sizing: border-box;
  &:hover {
    border: 1px solid ${colors.darkBlue200};
  }
  &:active {
    box-shadow: none;
  }
`;

interface Props {
  element?: HTMLElement | null;
}

export const ScrollToTop = ({ element }: Props) => {
  const isVisibleRef = useRef(false);
  const [isHidingInProgress, setIsHidingInProgress] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [buttonStyle, animateButton] = useSpring(() => ({
    right: -52,
    config: { tension: 220 },
  }));

  const scrollToTop = () => element?.scrollTo({ top: 0, behavior: "smooth" });
  const handlePressedButton = () => setIsPressed(true);
  const handleReleasedButton = () => setIsPressed(false);
  const animateHover = () => animateButton.start({ right: 0 });
  const showButton = async () => {
    // this function can be called, during hiding animation when user's mouse
    // is over the button, in that case we dont want to call onMouseLeave animation
    if (isHidingInProgress) return;
    isVisibleRef.current = true;
    animateButton.start({ right: -4 });
  };
  const hideButton = async () => {
    isVisibleRef.current = false;
    setIsHidingInProgress(true);
    await animateButton.start({ right: -52 })[0];
    setIsHidingInProgress(false);
  };

  const handleScroll = _.throttle(() => {
    const topPosition =
      element?.children[0]?.getBoundingClientRect().top || HEADER_HEIGHT;

    if (isVisibleRef.current && topPosition === HEADER_HEIGHT) hideButton();
    if (!isVisibleRef.current && topPosition < HEADER_HEIGHT) showButton();
  }, 500);

  useEffect(() => {
    if (!element) return;
    element.addEventListener("scroll", handleScroll);

    return () => element.removeEventListener("scroll", handleScroll);
  }, [element]);

  const iconColor = isPressed ? colors.primaryBlue900 : colors.primaryBlue500;

  return (
    <Container
      id={"scrollToTopButton"}
      onMouseEnter={animateHover}
      onMouseLeave={showButton}
      onMouseDown={handlePressedButton}
      onMouseUp={handleReleasedButton}
      onClick={scrollToTop}
      style={buttonStyle}
    >
      <UpCircleIcon fill={iconColor} />
    </Container>
  );
};
