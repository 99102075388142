import React, { ReactElement } from "react";

import { Text } from "components";
import { colors } from "config/theme";
import _ from "lodash";
import styled from "styled-components";

type LabelProps = { label: string };
type IconProps = { icon: ReactElement };

type Props = {
  label?: string;
  icon?: ReactElement;
  onClick?: () => void;
  isDisabled?: boolean;
  styles?: React.CSSProperties;
} & (LabelProps | IconProps);

const StyledButton = styled.button<{ isOnlyIcon: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: ${colors.darkBlue900};
  width: fit-content;
  padding: ${({ isOnlyIcon }) => (isOnlyIcon ? "8px" : "8px 16px")};
  &:hover {
    background: ${colors.darkBlue800};
  }
  &:active {
    background: ${colors.darkBlue500};
    box-shadow: none;
  }
  &:disabled {
    background: ${colors.grey300};
    box-shadow: none;
    cursor: default;
  }
`;

export const SecondaryButton = ({
  label,
  icon,
  onClick,
  isDisabled,
  styles: customStyle,
}: Props) => {
  const isOnlyIcon = !label && !!icon;

  return (
    <StyledButton
      id={`${_.camelCase(label)}SecondaryButton`}
      onClick={onClick}
      disabled={isDisabled}
      isOnlyIcon={isOnlyIcon}
      style={customStyle}
    >
      {!!icon && icon}
      {!!label && <Text fontType="button2">{label}</Text>}
    </StyledButton>
  );
};
