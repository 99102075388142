import { flags } from "config/flags";
import { MainNavigator } from "config/navigation/mainNavigator";
import { DesignSystemProvider } from "gps-design-system";
import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProvider, UserManager } from "oidc-react";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ForgottenPassword } from "routes";
import { Confirmation } from "routes/Confirmation";
import { Login } from "routes/Login";
import { MaintentancePage } from "routes/Maintenance";
import { PostLogin } from "routes/PostLogin";
import { SignUp } from "routes/SignUp";
import { TermsAndConditions } from "routes/TermsAndConditions";
import "./fonts/Px-Grotesk-Regular.woff";
import { GlobalStyle } from "./styles/global";

Modal.setAppElement("#root");

const queryClient = new QueryClient();

const REDIRECT_URI = `${process.env.REACT_APP_CLIENT}/signin-oidc`; // Must match value on IdentityServer

const userManager = new UserManager({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: `${process.env.REACT_APP_STS}`,
  client_id: `${process.env.REACT_APP_STS_CLIENT_ID}`,
  client_secret: `${process.env.REACT_APP_STS_CLIENT_SECRET}`,
  redirect_uri: REDIRECT_URI,
  silent_redirect_uri: REDIRECT_URI,
  post_logout_redirect_uri: `${process.env.REACT_APP_CLIENT}`,
  response_type: "code",
  scope:
    "openid email profile IdentityServerApi devportalapi identitymanagementapi",
  loadUserInfo: false,
  automaticSilentRenew: true,
});

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA.send("pageview");
}

export const AppComponent = () => {
  const redirectUri = new URL(REDIRECT_URI).pathname;

  const autoSignIn = [redirectUri, "/", "/login", "signin-oidc"].includes(
    window.location.pathname
  );

  return (
    <DesignSystemProvider>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <AuthProvider autoSignIn={autoSignIn} userManager={userManager}>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/T&C" element={<TermsAndConditions />} />
              <Route path="/verificationCode" element={<Confirmation />} />
              <Route
                path="/forgottenPassword"
                element={<ForgottenPassword />}
              />
              <Route path={redirectUri} element={<PostLogin />} />
              <Route path="/*" element={<MainNavigator />} />
            </Routes>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </DesignSystemProvider>
  );
};

const MaintenanceContainer = () => {
  const autoSignIn = [""].includes(window.location.pathname);

  return (
    <DesignSystemProvider>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <AuthProvider autoSignIn={!autoSignIn} userManager={userManager}>
          <MaintentancePage />
        </AuthProvider>
      </QueryClientProvider>
    </DesignSystemProvider>
  );
};

export const App = () =>
  flags["maintenanceMode"] ? <MaintenanceContainer /> : <AppComponent />;
