import {RefObject, useEffect} from 'react'

import {LEFT_MENU_TOP_LINKS, LEFT_MENU_BOTTOM_LINKS} from 'layouts/components/LeftMenu'

export const useMainPath = () => {
  const pathname = window.location.pathname
  const ALL_LINKS = [...LEFT_MENU_TOP_LINKS, ...LEFT_MENU_BOTTOM_LINKS];
  const mainPath = ALL_LINKS.reduce((previous, {link}) => {
    if (pathname.includes(link) && previous.length < link.length) {
      return link
    }
    return previous
  }, '')

  return {mainPath}
}

export const useOnClickOutside = (ref: RefObject<HTMLDivElement>, handler: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, handler])
}
