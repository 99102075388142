import {css} from 'styled-components'

export const slideModalStyles = css`
  #slideModal.ReactModal__Content {
    transform: translateX(100%);
    transition: transform 400ms ease;
  }
  #slideModal.ReactModal__Content--after-open {
    transform: translateX(0);
  }
  #slideModal.ReactModal__Content--before-close {
    transform: translateX(100%);
  }
  .ReactModal__Overlay {
    z-index: 20;
  }
`
