import { User } from "oidc-client-ts"
import jwtDecode from 'jwt-decode'

export const rgba = (hexCode: string, opacity: number): string => {
  const clearedHex = hexCode.replace('#', '')
  const hexFullLength =
    clearedHex.length === 3
      ? `${clearedHex[0]}${clearedHex[0]}${clearedHex[1]}${clearedHex[1]}${clearedHex[2]}${clearedHex[2]}`
      : clearedHex

  const r = parseInt(hexFullLength.substring(0, 2), 16)
  const g = parseInt(hexFullLength.substring(2, 4), 16)
  const b = parseInt(hexFullLength.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity})`
}

export const wait = (ms: number) => new Promise<void>(resolve => setTimeout(() => resolve(), ms))

export const decodeUser = (user?: User | null): {name: string; email: string; organizationName: string} => {
  const decoded = jwtDecode<any>(user?.access_token ?? '')
  const name = `${decoded.given_name} ${decoded.family_name}`
  return {name, email: decoded.email, organizationName: decoded.OrgName}
}
