import { ReactElement } from "react";

import { Row, Text } from "components";
import { colors } from "config/theme";
import styled from "styled-components";

const MenuItemWrapper = styled(Row)`
  min-height: 20px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  padding: 6px 0px;
`;

export interface MenuItemProps {
  id: string;
  text: string;
  icon?: ReactElement;
  onClick?: () => void;
  tabIndex?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const MenuItem = ({
  id,
  text,
  icon,
  onClick,
  tabIndex,
  onKeyDown,
}: MenuItemProps) => (
  <MenuItemWrapper
    id={id}
    onClick={onClick}
    tabIndex={tabIndex}
    onKeyDown={onKeyDown}
  >
    {icon}
    <Text id="SignOut2" fontType="body2Semibold" color={colors.darkBlue900}>
      {text}
    </Text>
  </MenuItemWrapper>
);
