import ResponseErrorHandler from "Services/ResponseErrorHandler";
import ForgottenPasswordService from "Services/forgottenPassword";
import { SuccessIcon } from "assets/icons";
import { BubbleMessage, Column, Div, Row, useBubbleMessage } from "components";
import { Button, Text, TextInput } from "gps-design-system";
import { Footer } from "layouts/components/Footer/Footer";
import { MainHeader } from "layouts/components/MainHeader/MainHeader";
import { useAuth } from "oidc-react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import Container from "styled/Container";
import { media } from "utils/media";
import tapImage from "../assets/Images/tap-image.png";

interface IFormInputs {
  email: string;
}

const DivMain = styled(Div)`
  width: 100%;
  background: #f6f2ef;
  min-height: 700px;
  position: relative;
`;

const LabelInput = styled(Column)<{ width?: string }>`
  width: ${({ width }) => (width ? width : "100%")};
`;
const RequiredSymbol = styled(Text)`
  color: #ff249e !important;
`;

const TapImage = styled(Div)`
  background: url(${process.env.PUBLIC_URL + tapImage});

  ${media.lg`
    width: 600px;
    height: 698px;
  `}
`;

const Title = styled.p`
  font-size: 42px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 60px;
  color: #2d3232;

  @media (min-width: 1700px) {
    font-size: 77px;
    line-height: 1;
  }
  @media (min-width: 1230px) {
    font-size: 60px;
    line-height: 1;
  }
  @media (min-width: 768px) {
    font-size: 48.5px;
    line-height: 1;
  }
`;
const EmailBlurbText = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #2d3232;
  ${media.md`
    font-size: 21px;
    font-weight: 700;
    line-height: 25.2px;
  `};
  ${media.lg`
    font-size: 26px;
    font-weight: 700;
    line-height: 31.2px;
  `}
`;

const BlurbText = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  ${media.sm`
    font-size: 21px;
    font-weight: 700;
    line-height: 25.2px;
  `};

  ${media.lg`
    font-size: 26px;
    font-weight: 700;
    line-height: 31.2px;
  `}
`;

const StyledTextInput = styled(TextInput)`
  font-size: 16px;
  line-height: 1.4px;
  font-weight: 300;
  min-height: 50px;
  padding: 12px;
  box-shadow: none;
`;

const LabelText = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  @media (min-width: 1050px) {
    font-size: 14px;
  }
`;

const CheckFormText = styled(Div)`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0em;
  ${media.md`
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
    `};

  ${media.lg`
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    `}
`;

const FormText = styled(Div)`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #2d3232;

  ${media.md`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `};

  ${media.lg`
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;

  `};
`;

const MainContainer = styled(Div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ede6df;
`;

const RequiredLabel = ({ label }: { label: string }) => (
  <Row>
    <LabelText>{label}</LabelText>
    <RequiredSymbol>*</RequiredSymbol>
  </Row>
);

const StyledTitle = styled(Title)`
  font-size: 34px;
  font-weight: 700;
  line-height: 37.4px;
  margin-bottom: 30px;
  letter-spacing: 0px;
  color: #2d3232;
  ${media.md`
    font-size: 48.5px;
    font-weight: 700;
    line-height: 48.5px;
    letter-spacing: 0px;
  `};

  ${media.lg`
    font-size: 59px;
    font-weight: 700;
    line-height: 70.8px;
  `}
`;

const FormContainer = styled(Div)`
  max-width: 100%;
  background: white;
  padding: 20px;

  ${media.lg`
    padding: 0;
    max-width: 390px;
    background: transparent;
  `}
`;

const WelcomeContainer = styled(Div)`
  display: flex;
  width: 100%;
  padding-top: 120px;
  justify-content: center;
  padding-bottom: 40px;
  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const EmailContainer = styled(Div)`
  display: flex;
  align-content: center;
  flex-direction: column;
  padding-top: 50px;
`;

const EmailSentContainer = styled(Div)`
  display: flex;
  flex-direction: column;
`;

//#region
export const ForgottenPassword = () => {
  const { handleSubmit } = useForm<IFormInputs>({ mode: "onChange" });
  const { isVisible, showMessageBubble } = useBubbleMessage();
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isWarning] = useState(false);
  const [emailFormErrors, setEmailFormErrors] = useState<any | null>();
  const [hasForbiddenSpecialCharacters, setHasForbiddenSpecialCharacters] =
    useState(false);
  const [isRequestButtonDisabled, setIsRequestButtonDisabled] = useState(true);
  const [showEmailSentPage, setShowEmailSentPage] = useState(false);
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [emailAddress, setEmailAddress] = useState("");

  const handleTextChange = () => {
    const errorMsg = "Input contains invalid character.";
    if (emailValidation(emailAddress)) {
      if (emailAddress.indexOf('"') > -1 || emailAddress.indexOf("\\") > -1) {
        setEmailFormErrors(errorMsg);
        setHasForbiddenSpecialCharacters(true);
        setIsRequestButtonDisabled(true);
      } else {
        setEmailFormErrors(null);
        setHasForbiddenSpecialCharacters(false);
        setIsRequestButtonDisabled(false);
      }
    }
  };

  const handleEmailChange = (e: any) => {
    const newEmail = e.trim();
    setEmailAddress(newEmail);
    emailValidation(newEmail);
  };

  const emailValidation = (email: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    const isValid = regex.test(email);
    setEmailFormErrors(
      isValid
        ? null
        : "The email is not in the right format. Please check and try again."
    );
    setIsRequestButtonDisabled(!isValid);
    return isValid;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const isEmailValid = emailValidation(emailAddress);
    data.email = emailAddress.trim();
    if (isEmailValid && !hasForbiddenSpecialCharacters) {
      setIsRequestButtonDisabled(true);
      ForgottenPasswordService.SubmitForgottenPassword(data.email)
        .then((resp) => {
          setIsRequestButtonDisabled(false);
          if (resp == "OK") {
            setShowEmailSentPage(true);
          }
        })
        .catch((err) => {
          setIsRequestButtonDisabled(false);
          const response = ResponseErrorHandler.HandleResponseErrors(
            err,
            auth,
            queryClient
          );
          showMessageBubble();
          setIsError(response.isError);
          setMessage(response.message);
        });
    }
  };

  return (
    <>
      <BubbleMessage
        isVisible={isVisible}
        message={message}
        isWarning={isWarning}
        isError={isError}
      />
      <MainContainer style={{ display: "flex", flexDirection: "column" }}>
        <DivMain>
          <Container>
            <MainHeader />
            <WelcomeContainer>
              {/* email container */}
              <EmailContainer>
                <StyledTitle>Welcome to Thredd</StyledTitle>

                <FormContainer>
                  {!showEmailSentPage ? (
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                      }}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <BlurbText>Forgotten Password</BlurbText>
                        <FormText>
                          Enter your email and we’ll send you a link to reset
                          your password.
                        </FormText>
                      </div>
                      <LabelInput>
                        <RequiredLabel label="Email address" />
                        <StyledTextInput
                          aria-label="emailAddress"
                          value={emailAddress}
                          onChange={(e) => {
                            handleEmailChange(e);
                          }}
                          isValid={emailFormErrors}
                          errorMessage={emailFormErrors}
                          width="100%"
                          placeholder="example@gmail.com"
                          id='forgottenPasswordEmail'
                        />
                      </LabelInput>
                      <Button
                        variant="primary"
                        label="Send link to email"
                        size="slimFullWidth"
                        data-testid='forgottenPasswordButton'
                      />
                    </form>
                  ) : (
                    <EmailSentContainer>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <SuccessIcon />
                        <EmailBlurbText>Email Sent</EmailBlurbText>
                      </div>
                      <CheckFormText>
                        Check your email and open the link we sent to continue
                        to password reset.
                      </CheckFormText>
                    </EmailSentContainer>
                  )}
                </FormContainer>
              </EmailContainer>
              <TapImage />
            </WelcomeContainer>
          </Container>
        </DivMain>
        <Footer footermode="light"></Footer>
      </MainContainer>
    </>
  );
};
