import {colors} from 'config/theme'
import {css} from 'styled-components'

export const scrollBarStyles = css`
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.grey500};
    border-radius: 30px;
  }
  ::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`
