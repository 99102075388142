import {
  ArrowRightIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "assets/icons";
import { Div, Row, Text } from "components";
import { MOBILE_WIDTH } from "config/constants";
import { colors } from "config/theme";
import useWindowResize from "hooks/useWindowResize";
import styled from "styled-components";
import Container from "styled/Container";
import { media } from "utils/media";
import FooterDock from "./FooterDock";

interface Props {
  footermode: string;
}

const Wrapper = styled(Div)<{ footermode: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  bottom: 0;
  width: 100%;
  padding: 110px 0 110px 0;
  position: relative;
  justify-content: center;
  background-color: ${(props) =>
    props.footermode == "dark" ? colors.darkBlue900 : colors.white};
  z-index: 10;
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin: 0 auto;
    gap: 20px;
    padding: 0 5%;
    flex-direction: column;
  }
  ${media.md`
    padding: 110px 40px 20px 40px;
  `}
`;

const FooterText = styled(Text)<{ footermode: string }>`
  align-self: center;
  cursor: pointer;
  font-size: 15px;

  color: ${(props) =>
    props.footermode == "dark" ? colors.primaryBlue100 : colors.primaryBlue500};
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
  }
`;
const TabColor = colors.primaryBlue500;

const FooterContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  margin: auto;
`;
const FooterRow = styled(Div)`
  display: flex;
  flex-direction: column;
  line-height: 27px;
  justify-content: center;

  ${media.xl`
    flex-direction: row;
    justify-content: space-between;
  `}
`;
const FooterColumn = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 220px;
`;
const SubColumn = styled.a`
  cursor: pointer;
  width: 150px;
  text-decoration: none;
  outline: 0;
  color: inherit;
  font-size: 15px;
  color: #2d3232;
  &:hover {
    text-decoration-line: underline;
  }
`;
const SocialMediaAction = styled(Div)`
  justify-content: flex-end;
  display: flex;
  margin: 5px;
  font-size: 22px;
  cursor: pointer;
`;

const SocialMediaRow = styled(Div)`
  display: flex;
  flex-direction: row;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin-bottom: 33px;
  }
`;

export const StyledHR = styled.hr`
  border-top: 1px solid #ececec;
  border-right: none;
  border-bottom: none;
  border-left: none;
`;

export const Footer = ({ footermode }: Props) => {
  const { width } = useWindowResize();
  const isMobile = width <= 1280;

  const FooterConfig = [
    {
      header: { text: "Solutions", href: "https://www.thredd.com/solutions" },
      links: [
        {
          text: "Issuer Processing",
          href: "https://www.thredd.com/solutions/issuer-processing",
        },
        { text: "Services", href: "https://www.thredd.com/solutions/services" },
        {
          text: "Working with us",
          href: "https://www.thredd.com/solutions/working-with-us",
        },
        {
          text: "Technology",
          href: "https://www.thredd.com/solutions/technology",
        },
      ],
    },
    {
      header: {
        text: "Clients",
        href: "https://www.thredd.com/clients-stories",
      },
      links: [
        {
          text: "Caxton",
          href: "https://www.thredd.com/clients-stories/caxton",
        },
        { text: "Nium", href: "https://www.thredd.com/clients-stories/nium" },
        { text: "Holvi", href: "https://www.thredd.com/clients-stories/holvi" },
        { text: "Fidoo", href: "https://www.thredd.com/clients-stories/fidoo" },
        {
          text: "Currensea",
          href: "https://www.thredd.com/clients-stories/currensea",
        },
        { text: "View more", href: "https://www.thredd.com/clients-stories" },
      ],
    },
    {
      header: { text: "Company", href: "https://www.thredd.com/about-us" },
      links: [
        { text: "About us", href: "https://www.thredd.com/about-us" },
        { text: "Careers", href: "https://www.thredd.com/about-us/careers" },
        { text: "Newsroom", href: "https://www.thredd.com/about-us/newsroom" },
        {
          text: "Our Investors",
          href: "https://www.thredd.com/about-us/our-investors",
        },
        { text: "Contact us", href: "https://www.thredd.com/get-in-touch" },
      ],
    },
    {
      header: {
        text: "All resources",
        href: "https://www.thredd.com/resources",
      },
      links: [
        {
          text: "Industry insights",
          href: "https://www.thredd.com/resources/industry-insights#",
        },
        {
          text: "Industry guides",
          href: "https://www.thredd.com/resources/industry-guides",
        },
        {
          text: "Events and webinars",
          href: "https://www.thredd.com/resources/events-and-webinars",
        },
        {
          text: "Payments glossary",
          href: "https://www.thredd.com/resources/industry-guides/payments-glossary",
        },
        { text: "FAQs", href: "https://www.thredd.com/resources/faqs" },
      ],
    },
    {
      header: {
        text: "Developer Portal",
        href: "https://www.thredd.com/developers",
      },
      links: [
        {
          text: "Sandbox",
          href: "https://devportal.thredd.com/",
        },
        { text: "Cards API", href: "https://cardsapidocs.thredd.com/" },
        { text: "Technical docs", href: "https://docs.thredd.com/" },
        {
          text: "Changelog",
          href: "https://cardsapidocs.thredd.com/changelog",
        },
      ],
    },
  ];

  const goToThredd = () => {
    return window.open("https://www.thredd.com/");
  };

  const goToTwitter = () => {
    return window.open("https://twitter.com/HelloThredd");
  };

  const goToLinkedin = () => {
    return window.open("https://www.linkedin.com/company/thredd");
  };

  const goToInstagram = () => {
    return window.open("https://www.instagram.com/HelloThredd/");
  };

  return (
    <Wrapper footermode={footermode}>
      <Container>
        <FooterRow>
          <FooterColumn
            style={
              isMobile
                ? {
                    paddingTop: "93px",
                    cursor: "pointer",
                    marginBottom: "33px",
                  }
                : { cursor: "pointer" }
            }
            onClick={goToThredd}
          >
            <img style={{ width: "108px" }} src="/Thredd_Logo_Indigo_RGB.svg" />
          </FooterColumn>
          {!isMobile && (
            <>
              {FooterConfig.map((footer, index) => (
                <FooterColumn key={index}>
                  <SubColumn
                    href={footer.header.href}
                    style={{
                      color: TabColor,
                      marginBottom: "5px",
                      fontSize: "16px",
                    }}
                  >
                    <Row gap={5}>
                      {footer.header.text}
                      <ArrowRightIcon style={{ alignSelf: "center" }} />
                    </Row>
                  </SubColumn>
                  {footer.links.map((link, i) => (
                    <SubColumn key={i} href={link.href}>
                      {link.text}
                    </SubColumn>
                  ))}
                </FooterColumn>
              ))}
            </>
          )}
          {isMobile && (
            <div style={{ paddingBottom: "67px" }}>
              {FooterConfig.map((footer, index) => (
                <FooterDock
                  key={index}
                  title={footer.header.text}
                  href={footer.header.href}
                  links={footer.links}
                />
              ))}
            </div>
          )}
        </FooterRow>
        <FooterRow
          style={isMobile ? { margin: "0" } : { margin: "75px 0 25px 0" }}
        >
          <SocialMediaRow>
            <SocialMediaAction onClick={goToLinkedin}>
              <LinkedInIcon />
            </SocialMediaAction>
            <SocialMediaAction onClick={goToTwitter}>
              <TwitterIcon />
            </SocialMediaAction>
            <SocialMediaAction onClick={goToInstagram}>
              <InstagramIcon />
            </SocialMediaAction>
          </SocialMediaRow>
          {!isMobile ? (
            <Row gap={25}>
              <Row gap={10}>
                <FooterText
                  onClick={() =>
                    window.open(
                      "https://www.thredd.com/privacy-policy/",
                      "_blank",
                      "noopener"
                    )
                  }
                  footermode={footermode}
                  fontType="paragraph03"
                >
                  Privacy policy
                </FooterText>
                <ArrowRightIcon style={{ alignSelf: "center" }} />
              </Row>
              <Row gap={5}>
                <FooterText
                  onClick={() =>
                    window.open(
                      "https://www.thredd.com/cookie-policy",
                      "_blank",
                      "noopener"
                    )
                  }
                  footermode={footermode}
                  fontType="paragraph03"
                >
                  Cookie policy
                </FooterText>
                <ArrowRightIcon style={{ alignSelf: "center" }} />
              </Row>
              <Row gap={5}>
                <FooterText
                  onClick={() =>
                    window.open(
                      "https://www.thredd.com/terms-and-conditions",
                      "_blank",
                      "noopener"
                    )
                  }
                  footermode={footermode}
                  fontType="paragraph03"
                >
                  Terms and conditions
                </FooterText>
                <ArrowRightIcon style={{ alignSelf: "center" }} />
              </Row>
            </Row>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px 0px 20px 4px",
                gap: "20x",
                color: "#251D97",
              }}
            >
              <Row gap={10}>
                <Row gap={10}>
                  <FooterText
                    onClick={() =>
                      window.open(
                        "https://www.thredd.com/privacy-policy/",
                        "_blank",
                        "noopener"
                      )
                    }
                    footermode={footermode}
                    fontType="paragraph03"
                  >
                    Privacy policy
                  </FooterText>
                  |
                </Row>
                <Row gap={10}>
                  <FooterText
                    onClick={() =>
                      window.open(
                        "https://www.thredd.com/cookie-policy",
                        "_blank",
                        "noopener"
                      )
                    }
                    footermode={footermode}
                    fontType="paragraph03"
                  >
                    Cookie policy
                  </FooterText>
                </Row>
              </Row>
              <Row gap={10} style={{ paddingLeft: "5px" }}>
                |
                <FooterText
                  onClick={() =>
                    window.open(
                      "https://www.thredd.com/terms-and-conditions",
                      "_blank",
                      "noopener"
                    )
                  }
                  footermode={footermode}
                  fontType="paragraph03"
                >
                  Terms and conditions
                </FooterText>
              </Row>
            </div>
          )}
        </FooterRow>
        <StyledHR />
        <FooterRow
          style={
            isMobile
              ? { margin: "10px", fontWeight: 300 }
              : { marginTop: "18px", fontWeight: 300 }
          }
        >
          &#169; 2024 Thredd UK Limited | Kingsbourne House, 229-231 High
          Holborn, London, WC1V 7DA | Company registration number 09926803
        </FooterRow>
        <FooterRow
          style={
            isMobile
              ? { margin: "10px", fontWeight: 300 }
              : { paddingTop: "18px", fontWeight: 300 }
          }
        >
          2024 All product names, logos, trademarks and registered trademarks
          are the property of their respective owners. All company, product and
          service names used in this websites are for identification purpose
          only.
        </FooterRow>
        <FooterRow
          style={
            isMobile ? { margin: "10px", fontWeight: 300 } : { fontWeight: 300 }
          }
        >
          {" "}
          Use of these names, logos, and brands does not imply endorsement.
        </FooterRow>
      </Container>
    </Wrapper>
  );
};
