import { CSSProperties } from "react";
import styled, { StyledComponent } from "styled-components";
import { media } from "utils/media";

interface ContainerProps {
  padding?: CSSProperties["padding"];
}

const Container: StyledComponent<
  "div",
  any,
  ContainerProps
> = styled.div<ContainerProps>`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: ${(props) => props.padding || "16px"};
  padding-right: ${(props) => props.padding || "16px"};
  ${media.sm`
    width: 100%;
  `};

  ${media.md`
    width: 100%
  `};

  ${media.lg`
    width: 100%
  `};

  ${media.xl`
    width: 1280px;
  `};
`;

export default Container;
